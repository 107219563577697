import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationIntentService {
  private readonly navigationIntent = new BehaviorSubject<string | null>(null);

  constructor(private readonly router: Router) {}

  setIntent(url: string): void {
    this.navigationIntent.next(url);
  }

  clearIntent(): void {
    this.navigationIntent.next(null);
  }

  getIntent(): string | null {
    return this.navigationIntent.value;
  }

  navigateToIntentOrDefault(defaultUrl: string = '/'): void {
    const intent = this.getIntent();
    if (intent) {
      this.router.navigateByUrl(intent);
      this.clearIntent();
    } else {
      this.router.navigateByUrl(defaultUrl);
    }
  }
}
