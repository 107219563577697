export enum EVerifyAttributeName {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number'
}

export enum EAuthType {
  Login = 'login',
  SignUp = 'signUp'
}

export enum EUserAuthType {
  Email_Password = 'email-password',
  Google_Sign_In = 'google-sign-in'
}
