import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  IConversationHistoryResponse,
  IConversationsListResponse,
  IMessage,
  IConversationStatusResponse,
  IGroupedMessages,
  IConversationSharedMediaResponse
} from '../../interfaces/conversations.interfaces';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  private apiUrl = environment.apiBaseUrl;
  historyEndpoint: string;

  constructor(private http: HttpClient) {}

  getConversationStatus(creatorId: string): Observable<IConversationStatusResponse> {
    const endpoint = this.apiUrl + `/chats/${creatorId}/status`;
    return this.http.get<IConversationStatusResponse>(endpoint);
  }

  getConversationsList(): Observable<IConversationsListResponse> {
    const endpoint = this.apiUrl + '/chats';
    return this.http.get<IConversationsListResponse>(endpoint);
  }

  getConversationHistory(
    creatorId: string,
    offset?: string | null
  ): Observable<IConversationHistoryResponse> {
    if (!offset) {
      this.historyEndpoint = this.apiUrl + `/chats/${creatorId}/messages?limit=20`;
    } else {
      this.historyEndpoint =
        this.apiUrl + `/chats/${creatorId}/messages?limit=20` + `&offset=${offset}`;
    }
    return this.http.get<IConversationHistoryResponse>(this.historyEndpoint);
  }

  getConversationSharedMedia(
    creatorId: string,
    offset?: string | null
  ): Observable<IConversationSharedMediaResponse> {
    if (!offset) {
      this.historyEndpoint = this.apiUrl + `/chats/${creatorId}/vault?limit=20`;
    } else {
      this.historyEndpoint =
        this.apiUrl + `/chats/${creatorId}/vault?limit=20` + `&offset=${offset}`;
    }
    return this.http.get<IConversationSharedMediaResponse>(this.historyEndpoint);
  }

  getConversationMessage(
    creatorId: string,
    timestamp: number
  ): Observable<IConversationHistoryResponse> {
    const endpoint = this.apiUrl + `/chats/${creatorId}/messages?timestamp=${timestamp}`;
    return this.http.get<IConversationHistoryResponse>(endpoint);
  }

  getFormattedDate(inputDate: Date | string | number): string {
    const currentDate = new Date();
    let dateObject: Date;

    if (typeof inputDate === 'string' || typeof inputDate === 'number') {
      dateObject = new Date(inputDate);
    } else {
      dateObject = inputDate as Date;
    }

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const inputYear = dateObject.getFullYear();
    const inputMonth = dateObject.getMonth();
    const inputDay = dateObject.getDate();
    const inputHour = dateObject.getHours();
    const inputMinute = dateObject.getMinutes();

    const oneMinute = 60 * 1000; // milliseconds in a minute

    const timeDifference = currentDate.getTime() - dateObject.getTime();

    if (timeDifference < oneMinute) {
      return 'Just now';
    } else if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay === inputDay
    ) {
      return ConversationService.formatTime(inputHour, inputMinute);
    } else if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay - inputDay === 1
    ) {
      return 'Yesterday';
    } else if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay - inputDay < 7
    ) {
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      return daysOfWeek[dateObject.getDay()];
    } else {
      const year = dateObject.getFullYear();
      const month = ConversationService.padZeroes(dateObject.getMonth() + 1);
      const day = ConversationService.padZeroes(dateObject.getDate());
      return `${year}/${month}/${day}`;
    }
  }

  private static padZeroes(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  private static formatTime(hours: number, minutes: number): string {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour = hours % 12 || 12;
    const minute = ConversationService.padZeroes(minutes);
    return `${hour}:${minute} ${ampm}`;
  }

  groupMessagesByDate(messages: IMessage[]): IGroupedMessages[] {
    const groupedMessages: IGroupedMessages[] = [];
    let currentDate: Date = null;

    messages.forEach(message => {
      const messageDate: Date = new Date(Number(message.timestamp));

      if (!currentDate || !this.areDatesSameDay(currentDate, messageDate)) {
        currentDate = messageDate;
        groupedMessages.push({
          date: this.formatDate(currentDate),
          messages: []
        });
      }

      groupedMessages[groupedMessages.length - 1].messages.push(message);
    });

    return groupedMessages;
  }

  areDatesSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  formatDate(date: Date): string {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.areDatesSameDay(date, today)) {
      return 'Today';
    } else if (this.areDatesSameDay(date, yesterday)) {
      return 'Yesterday';
    } else if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
      return this.getDayOfWeek(date) + ' ' + this.formatTime(date);
    } else {
      if (date.getFullYear() === today.getFullYear()) {
        return this.getMonthDay(date);
      } else {
        return this.getMonthDay(date) + ' ' + date.getFullYear();
      }
    }
  }

  formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  getDayOfWeek(date: Date): string {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  }

  getMonthDay(date: Date): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    return `${months[date.getMonth()]} ${date.getDate()}`;
  }
}
