import { Injectable }        from '@angular/core';
import { HttpClient }        from '@angular/common/http';
import { Observable }        from 'rxjs';
import { environment }       from 'environments/environment';
import { ICreatorsResponse } from 'app/interfaces/creator.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DiscoverService {
  private apiUrl = `${ environment.apiBaseUrl }/discover`;

  constructor(private http: HttpClient) {
  }

  getDiscoverData(): Observable<ICreatorsResponse> {
    return this.http.get<ICreatorsResponse>(this.apiUrl);
  }

  getDiscoverCreatorData(creatorId: string): Observable<ICreatorsResponse> {
    const endpoint = this.apiUrl + '/' + creatorId;
    return this.http.get<ICreatorsResponse>(endpoint);
  }
}
