<div class="creator_media__video">
  <video
    [ngStyle]="{ 'border-radius': videoType === ECreatorVideoTypes.Preview ? '20px' : '0' }"
    id="{{ mediaId }}"
    src="{{ media }}"
    (ended)="isPlaying = false"
  ></video>
  <button *ngIf="!isPlaying" class="play-pause-button" (click)="togglePlayPause($event)">
    <stxt-svg-icon class="play_svg" [iconConfig]="playIcon"></stxt-svg-icon>
  </button>
  <button
    *ngIf="isPlaying"
    class="play-pause-button pause-button"
    (click)="togglePlayPause($event)"
  >
    <stxt-svg-icon class="play_svg" [iconConfig]="pauseIcon"></stxt-svg-icon>
  </button>
</div>
