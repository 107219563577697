/* eslint-disable */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket$: WebSocketSubject<any>;
  private wsServer: string = environment.wssBaseUrl;
  public wsConnectionId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pingInterval: Subscription;
  private pingMessage = { action: 'ping' };

  public connect(sessionToken: string, creatorId: string): void {
    const wsConnectionUrl = `${this.wsServer}?BearerToken=${encodeURIComponent(sessionToken)}&creatorId=${creatorId}`;
    let connectionId = '';
    this.socket$ = webSocket({
      url: wsConnectionUrl,
      serializer: value => JSON.stringify(value), // Serialize outgoing messages to JSON
      deserializer: e => {
        try {
          const msg = JSON.parse(e.data);
          if (msg.cid) {
            connectionId = msg.cid;
            this.wsConnectionId$.next(connectionId);
          }
          return msg.data; // Parse incoming JSON messages
        } catch (error) {
          return e.data; // Return plain text messages as is
        }
      },
      openObserver: {
        next: e => {
          console.log('CONNECT: ' + JSON.stringify(e));
          this.startPing();
        }
      },
      closeObserver: {
        next: e => {
          console.log('DISCONNECT: ' + JSON.stringify(e));
          this.stopPing();
        }
      }
    });
  }

  private startPing(): void {
    this.pingInterval = timer(0, 30000).subscribe(() => {
      this.socket$.next(this.pingMessage);
    });
  }

  private stopPing(): void {
    if (this.pingInterval) {
      this.pingInterval.unsubscribe();
    }
  }

  public sendAction(action: string, payload: any): void {
    const message = { action, payload };
    this.socket$.next(message);
  }

  public onMessage(): Observable<any> {
    return this.socket$.asObservable();
  }

  public closeConnection(): void {
    if (!this.socket$) return;
    this.socket$.complete();
  }
}
