import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { first } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxMasonryModule } from 'ngx-masonry';

import { CreatorService } from '../../shared/services/creator.service';

import { CreatorMediaCardComponent } from '../../components/creator-media-card/creator-media-card.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { MediaLightboxComponent } from '../../components/media-lightbox/media-lightbox.component';
import { MasonryGalleryComponent } from '../../shared/components/masonry-gallery/masonry-gallery.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import { IButtonConfig } from '../../shared/interfaces/button.interfaces';
import { ICreatorContent, ICreatorData } from '../../interfaces/creator.interfaces';

import { EButtonSizes, EButtonTypes } from '../../shared/enums/button.enums';
import { ESvgTypes } from '../../shared/enums/svg.enums';
import { ECreatorMediaTypes } from '../../enums/creator.enums';

@Component({
  selector: 'stxt-creator-profile-page',
  standalone: true,
  imports: [
    SvgIconComponent,
    ButtonComponent,
    CommonModule,
    NgxMasonryModule,
    CreatorMediaCardComponent,
    MediaLightboxComponent,
    MasonryGalleryComponent
  ],
  templateUrl: './creator-profile-page.component.html',
  styleUrl: './creator-profile-page.component.scss',
  providers: [ReCaptchaV3Service]
})
export class CreatorProfilePageComponent implements OnInit {
  public ageIcon: ISvgConfig = {
    name: 'birthday',
    fill: ESvgTypes.None
  };
  public heightIcon: ISvgConfig = {
    name: 'height',
    fill: ESvgTypes.None
  };
  public ethnicityIcon: ISvgConfig = {
    name: 'ethnicity',
    fill: ESvgTypes.None
  };
  chatIcon: ISvgConfig = {
    name: 'conversation',
    fill: ESvgTypes.None
  };
  public leftIcon: ISvgConfig = {
    name: 'arrow_left',
    fill: ESvgTypes.ColorFilledDarker
  };
  public moreIcon: ISvgConfig = {
    name: 'more',
    fill: ESvgTypes.ColorFilledDarker
  };
  public shareIcon: ISvgConfig = {
    name: 'share',
    fill: ESvgTypes.ColorFilledDarker
  };
  public chatNowButtonConfig: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default,
    text: 'Chat now'
  };
  creatorId = this.route.snapshot.paramMap.get('creatorId');
  creatorData: ICreatorData;
  userId: string = localStorage.getItem('userId');
  showChatCTA: boolean = false;
  creatorCardSelector = '.creator_button__desktop';
  user = JSON.parse(localStorage.getItem('user'));
  mediaList: ICreatorContent[] = [];
  mediaFullScreen: boolean = false;
  selectedMedia: ICreatorContent;
  protected readonly ECreatorMediaTypes = ECreatorMediaTypes;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const creatorCard = document.querySelector(this.creatorCardSelector);
    if (creatorCard) {
      const cardRect = creatorCard.getBoundingClientRect();
      const cardTop = cardRect.top;
      const scrollThreshold = 350;
      this.showChatCTA = window.scrollY > cardTop + scrollThreshold;
    }
  }

  constructor(
    public creatorService: CreatorService,
    public router: Router,
    public location: Location,
    public route: ActivatedRoute,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    this.getCreatorData();
  }

  getCreatorData(): void {
    this.creatorService
      .getCreatorData(this.creatorId)
      .pipe(first())
      .subscribe((res: ICreatorData) => {
        this.creatorData = res;
        this.mediaList = res.content.filter(
          item => item.type === ECreatorMediaTypes.Image || item.type === ECreatorMediaTypes.Video
        );
      });
  }

  navigateBack(): void {
    this.location.back();
  }

  openChat(): void {
    this.reCaptchaV3Service
      .execute('chat_with_creator')
      .pipe(first())
      .subscribe(() => {
        localStorage.setItem('backUrl', `/creator/${this.creatorData.id}`);
        this.router.navigateByUrl(`chat/${this.creatorData.id}`);
      });
  }
}
