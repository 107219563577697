import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { ProfileOptionComponent } from '../profile-option/profile-option.component';

import { IProfileOption, IUserPreferences } from 'app/interfaces/profile.interfaces';

@Component({
  selector: 'stxt-preferences',
  standalone: true,
  imports: [CommonModule, ProfileOptionComponent],
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent implements OnChanges {
  @Input() interestsData: string[];
  @Input() genderData: string;

  identifyOptions: IProfileOption[] = [
    { label: 'Male', value: 'male', selected: false },
    { label: 'Female', value: 'female', selected: false },
    { label: 'Transgender', value: 'transgender', selected: false }
  ];
  interestsOptions: IProfileOption[] = [
    { label: 'Women', value: 'women', selected: false },
    { label: 'Men', value: 'men', selected: false },
    { label: 'Trans women', value: 'trans_women', selected: false },
    { label: 'Trans men', value: 'trans_men', selected: false }
  ];
  selectedInterests: string[] = [];
  selectedGender: string;

  constructor(private profileService: ProfileService) {}

  ngOnChanges(): void {
    this.genderData ? (this.selectedGender = this.genderData) : '';
    this.interestsData ? (this.selectedInterests = this.interestsData) : [];
    this.setSelectedOption(this.genderData);
    this.setSelectedInterests(this.interestsData);
  }

  selectGender(selectedOption: IProfileOption): void {
    if (selectedOption.selected) return;
    selectedOption.selected = true;
    this.identifyOptions.forEach(option => {
      if (option !== selectedOption) {
        option.selected = false;
      }
    });
    this.selectedGender = selectedOption.value;
    this.saveProfilePreferences();
  }

  toggleInterest(selectedOption: IProfileOption): void {
    selectedOption.selected = !selectedOption.selected;
    if (selectedOption.selected) {
      this.selectedInterests.push(selectedOption.value);
      this.saveProfilePreferences();
    } else {
      this.selectedInterests = this.selectedInterests.filter(
        interest => interest !== selectedOption.value
      );
      this.saveProfilePreferences();
    }
  }

  setSelectedOption(gender: string): void {
    this.identifyOptions.forEach(option => {
      option.selected = option.value === gender?.toLowerCase();
    });
  }

  setSelectedInterests(interests: string[]): void {
    this.interestsOptions.forEach(option => {
      option.selected = interests?.includes(option.value?.toLowerCase());
    });
  }

  saveProfilePreferences(): void {
    const userPreferences: IUserPreferences = {
      gender: this.selectedGender?.toLowerCase(),
      interests: this.selectedInterests
    };
    this.profileService
      .updateProfileData<IUserPreferences>(userPreferences)
      .pipe(first())
      .subscribe();
  }
}
