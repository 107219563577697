"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STORAGE_PREFIX = exports.LOCAL_STORAGE_KEYS = exports.STATSIG_LOCAL_STORAGE_LOGGING_REQUEST_KEY = exports.STATSIG_STABLE_ID_KEY = exports.INTERNAL_STORE_KEY = exports.STICKY_DEVICE_EXPERIMENTS_KEY = exports.OVERRIDES_STORE_KEY = void 0;
exports.OVERRIDES_STORE_KEY = 'STATSIG_LOCAL_STORAGE_INTERNAL_STORE_OVERRIDES_V3';
exports.STICKY_DEVICE_EXPERIMENTS_KEY = 'STATSIG_LOCAL_STORAGE_STICKY_DEVICE_EXPERIMENTS';
// V4 change: values are now cached on a specific user ID
// We store values for up to 10 different user IDs at a time.
exports.INTERNAL_STORE_KEY = 'STATSIG_LOCAL_STORAGE_INTERNAL_STORE_V4';
exports.STATSIG_STABLE_ID_KEY = 'STATSIG_LOCAL_STORAGE_STABLE_ID';
exports.STATSIG_LOCAL_STORAGE_LOGGING_REQUEST_KEY = 'STATSIG_LOCAL_STORAGE_LOGGING_REQUEST';
exports.LOCAL_STORAGE_KEYS = {
  STATSIG_LOCAL_STORAGE_STABLE_ID: true,
  STATSIG_LOCAL_STORAGE_INTERNAL_STORE_V4: true,
  STATSIG_LOCAL_STORAGE_STICKY_DEVICE_EXPERIMENTS: true,
  STATSIG_LOCAL_STORAGE_INTERNAL_STORE_OVERRIDES_V3: true,
  STATSIG_LOCAL_STORAGE_LOGGING_REQUEST: true
};
exports.STORAGE_PREFIX = 'STATSIG_LOCAL_STORAGE';