<div class="security">
  <h4 class="security_heading">Security</h4>
  <p class="security_subheading">Manage your login credentials and social apps</p>
  <div class="security_items flex flex-column">
    <div class="security_item">
      <p class="security_item__heading">Login credentials</p>
      <p class="security_item__subheading">
        <!-- * TODO -- Line hidden because manage password is not active * -->
        <!--        Set your email address and password to access your account-->
        Set your email address to access your account
      </p>
      <div class="credentials_item flex space-between items-center">
        <div>
          <p class="credentials_item__name">Email address</p>
          <p class="credentials_item__value">{{ userEmail }}</p>
        </div>
        <div class="badge-wrapper">
          <div class="verified-badge" *ngIf="emailVerified">
            <stxt-svg-icon
              class="verified-badge_svg"
              [iconConfig]="verifiedSvgConfig"
            ></stxt-svg-icon>
            <span class="verified-badge__text">Verified</span>
          </div>
          <div class="not-verified_badge" *ngIf="!emailVerified">
            <stxt-svg-icon
              class="not-verified_badge_svg"
              [iconConfig]="notVerifiedSvgConfig"
            ></stxt-svg-icon>
            <span class="not-verified_badge__text">Not verified</span>
          </div>
          <stxt-svg-icon
            *ngIf="!emailVerified"
            [iconConfig]="arrowSvgConfig"
            (click)="openManageScreen.emit(EUserCredential.Email)"
            (keydown.enter)="openManageScreen.emit(EUserCredential.Email)"
            tabindex="-1"
            class="security_arrow-svg"
          ></stxt-svg-icon>
        </div>
      </div>
      <!-- * TODO -- Hidden for V1 * -->
      <!--      <div class="credentials_item flex space-between items-center">-->
      <!--        <div>-->
      <!--          <p class="credentials_item__name">Password</p>-->
      <!--           <p class="credentials_item__value">Last updated on DD/MM/YYYY</p> -->
      <!--        </div>-->
      <!--        <stxt-svg-icon (click)="openManageScreen.emit(EUserCredential.Password)"-->
      <!--                       class="security_arrow-svg"-->
      <!--                       [iconConfig]="arrowSvgConfig">-->
      <!--        </stxt-svg-icon>-->
      <!--      </div>-->
    </div>

    <!-- * We will be re-enabling this feature in the near future.
          For the time being, will be disabled * -->

    <!-- <div class="security_item">
      <p class="security_item__heading">Connect social apps</p>
      <p class="security_item__subheading">
        Connect social apps and to sign in to your STXT account with them.
      </p>
      <div class="connect_item flex space-between items-center">
        <p class="connect_name">Google</p>
        <stxt-button [buttonConfig]="connectBtnConfig" class="connect_btn">{{ connectBtnConfig.text }}</stxt-button>
      </div>
    </div> -->
  </div>
  <div *ngIf="!emailVerified" class="security_verify flex items-center">
    <div class="flex items-center">
      <stxt-svg-icon
        class="not-verified_badge_svg"
        [iconConfig]="notVerifiedSvgConfig"
      ></stxt-svg-icon>
      <p class="security_verify__text">Secure your email address for account login</p>
    </div>
    <div
      class="security_verify__button"
      (click)="openVerifyEmail()"
      (keydown.enter)="openVerifyEmail()"
      tabindex="-1"
    >
      Verify
    </div>
  </div>
</div>
