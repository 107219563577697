import { CommonModule }             from '@angular/common';
import { Component, Input } from '@angular/core';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig }        from 'app/shared/interfaces/svg.interfaces';
import { IUserSubscription } from 'app/interfaces/profile.interfaces';

import { ESubscriptionState } from 'app/enums/purchases.enums';
import { ESvgTypes }          from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-purchases-subscription-card',
  standalone: true,
  imports: [ CommonModule, SvgIconComponent ],
  templateUrl: './purchases-subscription-card.component.html',
  styleUrl: './purchases-subscription-card.component.scss'
})
export class PurchasesSubscriptionCardComponent {
  @Input() subscriptionItem: IUserSubscription;
  public arrowRightIcon: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };
  public SubscriptionState = ESubscriptionState;
}
