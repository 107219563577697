<div class="flex message-box">
  <p *ngIf="!messageItem.from_stxt" class="message_time">
    {{ messageItem.timestamp | date: 'shortTime' }}
  </p>
  <ng-container *ngIf="messageItem?.category !== 'medias'">
    <div
      (click)="clicked.emit()"
      (keydown.enter)="clicked.emit()"
      [ngClass]="{
        ai_message: messageItem.from_stxt === true,
        user_message: messageItem.from_stxt === false
      }"
      class="message flex items-center"
      tabindex="0"
    >
      <ng-container *ngIf="(messageItem?.category === 'voice' && safeUrl) || voiceUrl">
        <stxt-message-audio
          [creatorId]="creatorId"
          [creatorName]="creatorName"
          [safeUrl]="safeUrl || voiceUrl"
          [userId]="userId"
          class="w-full"
        ></stxt-message-audio>
      </ng-container>
      <p
        *ngIf="messageItem.category !== 'voice'"
        [innerHTML]="messageItem?.payload?.message"
        class="message_text"
      ></p>
      <p *ngIf="messageItem.category === 'voice'" class="message_voice">
        <span
          [innerHTML]="truncateVoiceText(messageItem?.payload?.message, isMessageExpanded)"
        ></span>
        <a
          *ngIf="messageItem?.payload?.message.length > 30"
          (click)="expandVoiceText()"
          (keydown.enter)="expandVoiceText()"
          tabindex="0"
        >
          {{ isMessageExpanded ? 'Show less' : 'Show more' }}
        </a>
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="messageItem?.category === 'medias'">
    <stxt-message-media
      (imagesLoaded)="mediaLoaded = true"
      (isPreviewMode)="isPreviewMode.emit($event)"
      (openPayment)="triggerPayment.emit()"
      [media]="messageItem.payload"
    ></stxt-message-media>
  </ng-container>
  <p *ngIf="messageItem.from_stxt" class="message_time">
    {{ messageItem.timestamp | date: 'shortTime' }}
  </p>
</div>
