import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { PaymentMethodListComponent } from './payment-method-list/payment-method-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AddPaymentMethodComponent } from './add-payment-method/add-payment-method.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import {
  IPaymentResult,
  IPurchaseData,
  ISubscriptionData
} from '../../interfaces/payments.interfaces';
import { IBillingInfoData } from 'app/interfaces/profile.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';
import { EPaymentStatus } from 'app/enums/payments.enums';

@Component({
  selector: 'stxt-payment',
  standalone: true,
  imports: [
    PaymentMethodListComponent,
    CheckoutComponent,
    CommonModule,
    AddPaymentMethodComponent,
    SvgIconComponent
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent implements OnInit {
  @Input() creator: string;
  @Input() creatorId: string;
  @Input() paymentType: string;
  @Input() wsConnectionId: string;
  @Input() subscriptionData?: ISubscriptionData;
  @Input() purchaseData?: IPurchaseData;
  @Output() closePaymentEvent: EventEmitter<IPaymentResult> = new EventEmitter<IPaymentResult>();
  public closeSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };
  public firstCheckOut: boolean = true;
  public dataLoaded: boolean = false;
  public activeWindow: string = 'checkout';
  public billingData: IBillingInfoData;
  public user = JSON.parse(localStorage.getItem('user'));
  public EPaymentStatus = EPaymentStatus;

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.getBillingInfo();
  }

  getBillingInfo(): void {
    this.profileService
      .getBillingInfo()
      .pipe(first())
      .subscribe({
        next: (res: IBillingInfoData) => {
          this.billingData = res;
          this.dataLoaded = true;
        },
        error: (err: Error) => {
          console.log(err);
          this.dataLoaded = true;
        }
      });
  }

  closePayment(event: IPaymentResult): void {
    this.closePaymentEvent.emit(event);
  }
}
