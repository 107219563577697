<div class="flex message-audio">
  <section *ngIf="isAudioQueried" class="section">
    <div class="controls">
      <button (click)="onPlayButtonClick()" *ngIf="!play" class="controls_button">
        <svg viewBox="0 0 24 24">
          <path  d="M8,5.14V19.14L19,12.14L8,5.14Z"/>
        </svg>
      </button>
      <button (click)="onPauseButtonClick()" *ngIf="play" class="controls_button">
        <svg viewBox="0 0 24 24">
          <path  d="M14,19H18V5H14M6,19H10V5H6V19Z"/>
        </svg>
      </button>
    </div>
  </section>
  <div #wave class="message-audio_wave"></div>
  <p class="message-audio_time">{{timeLeft | timeFormat}}</p>
</div>
