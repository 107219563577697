import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

import { IUserAttributeVerify } from './auth.interfaces';

import { EUserAuthType } from './auth.enums';

import { analytics } from '../../main';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  token = localStorage.getItem('accessToken');
  cognitoAccessToken = localStorage.getItem('cognitoAccessToken');
  private apiUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  getAuthToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  getCognitoAccessToken(): string | null {
    return localStorage.getItem('cognitoAccessToken');
  }

  async checkUserCredentials(): Promise<void> {
    const token = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    if (
      !token &&
      (user?.type === EUserAuthType.Google_Sign_In || user?.type === EUserAuthType.Email_Password)
    ) {
      this.userExist$.next(true);
    } else if (!token && !user) {
      this.userLoaded$.next(false);
    } else if (token && user) {
      this.userLoaded$.next(true);
    }
  }

  /**
   * Retrieves the current user's session information from an authentication service,
   * typically Cognito. It attempts to get the current user's details and access token.
   * @returns A promise that resolves once the session retrieval process is complete.
   */
  async currentSession(): Promise<void> {
    try {
      const user = await getCurrentUser();
      let token;
      let cognitoAccessToken;

      await fetchAuthSession().then(res => {
        const stxt_sub = res.tokens.idToken.payload['stxt_sub'] as string;
        localStorage.setItem('userId', stxt_sub);
      });

      if (user.username.includes('Google')) {
        token = localStorage.getItem(
          `CognitoIdentityServiceProvider.${environment.userPoolClientId}.${user.username}.idToken`
        );
        cognitoAccessToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.${environment.userPoolClientId}.${user.username}.accessToken`
        );
      } else {
        token = localStorage.getItem(
          `CognitoIdentityServiceProvider.${environment.userPoolClientId}.${user.userId}.idToken`
        );
        cognitoAccessToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.${environment.userPoolClientId}.${user.userId}.accessToken`
        );
      }

      localStorage.setItem('accessToken', token);
      localStorage.setItem('cognitoAccessToken', cognitoAccessToken);
      this.$isUser.next(true);
      this.userLoaded$.next(true);
    } catch (err) {
      // If an error occurs, update observable to indicate user is not authenticated
      this.$isUser.next(false);
    }
  }

  async setCognitoAccessToken(): Promise<void> {
    try {
      await getCurrentUser().then(user => {
        this.cognitoAccessToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.${environment.userPoolClientId}.${user.userId}.accessToken`
        );
        localStorage.setItem('cognitoAccessToken', this.cognitoAccessToken);
      });
    } catch (err) {
      console.error(err);
    }
  }

  identifyUser(signUpType: string, email?: string): void {
    fetchAuthSession().then(res => {
      const stxt_sub = res?.tokens?.idToken.payload['stxt_sub'] as string;
      analytics.identify(stxt_sub, {
        email: email
      });
    });
  }

  verifyCode(verifyData: IUserAttributeVerify): Observable<string> {
    const url = `${this.apiUrl}/users/verify`;
    return this.http.post<string>(url, verifyData);
  }

  resendVerifyCode(resendPayload: IUserAttributeVerify): Observable<string> {
    const url = `${this.apiUrl}/users/verify-resend`;
    return this.http.post<string>(url, resendPayload);
  }
}
