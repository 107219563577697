import { Component, Input } from '@angular/core';

import { IProfileOption } from 'app/interfaces/profile.interfaces';

@Component({
  selector: 'stxt-profile-option',
  standalone: true,
  imports: [],
  templateUrl: './profile-option.component.html',
  styleUrl: './profile-option.component.scss'
})
export class ProfileOptionComponent {
  @Input() option: IProfileOption;
}
