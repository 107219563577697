import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'stxt-switch',
  standalone: true,
  imports: [],
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent {
  @Input() isOn: boolean = false;
  @Output() stateChange = new EventEmitter<boolean>();

  toggleSwitch() {
    this.isOn = !this.isOn;
    this.stateChange.emit(this.isOn);
  }
}
