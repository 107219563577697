<div class="overlay">
  <div class="age-verification">
    <div class="age-verification_logo">
      <img class="age-verification_logo__img" src="assets/logo/Logo.svg" alt="age-verification" />
    </div>
    <div class="age-verification_body">
      <div class="age-verification_header">
        <p>VIRTUALLY IRRESISTIBLE AIs</p>
      </div>
      <stxt-button
        ngbAutofocus
        class="age-verification_btn-confirm"
        [buttonConfig]="confirmAgeBtn"
        (click)="confirmAge()"
        (keydown.enter)="confirmAge()"
      >
        {{ confirmAgeBtn.text }}
      </stxt-button>
      <stxt-button
        ngbAutofocus
        class="age-verification_btn-close"
        [buttonConfig]="closeBtn"
        (click)="closeModal()"
        (keydown.enter)="closeModal()"
      >
        {{ closeBtn.text }}
      </stxt-button>
      <p class="age-verification_agreement">
        By clicking ‘I’m 18+ • Enter Now’, you agree to our
        <span (click)="openTOS()" (keydown.enter)="openTOS()" tabindex="0">Terms of Use</span>
        and confirm you are above the age of 18 or the age of majority in your location.
      </p>
    </div>
  </div>
</div>
