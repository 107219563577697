import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ButtonComponent} from '../../../shared/components/button/button.component';
import {SvgIconComponent} from '../../../shared/components/svg-icon/svg-icon.component';

import {IButtonConfig} from '../../../shared/interfaces/button.interfaces';
import {ISvgConfig} from '../../../shared/interfaces/svg.interfaces';

import {EButtonTypes} from '../../../shared/enums/button.enums';
import {ESvgTypes} from '../../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-payment-method-list',
  standalone: true,
  imports: [ButtonComponent, SvgIconComponent],
  templateUrl: './payment-method-list.component.html',
  styleUrl: './payment-method-list.component.scss'
})
export class PaymentMethodListComponent {
  @Input() paymentType: string;
  @Output() goBackEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() newPaymentEvent: EventEmitter<void> = new EventEmitter<void>();
  public addMethodBtn: IButtonConfig = {text: 'Add payment method', fill: EButtonTypes.Colored};
  public arrowSvg: ISvgConfig = {fill: ESvgTypes.OutlinedDark, name: 'arrow_left'};

  goBack(): void {
    this.goBackEvent.emit();
  }

  addNewPayment(): void {
    this.newPaymentEvent.emit();
  }

}
