export enum EButtonTypes {
  Enabled = 'enabled',
  Hovered = 'hovered',
  Focused = 'focused',
  Disabled = 'disabled',
  Filled = 'filled',
  Colored = 'colored',
  LightColored = 'light-colored',
  Dark = 'dark',
  Empty = 'empty',
  SecondaryEmpty ='secondary-empty',
  SecondaryFilled ='secondary-filled',
  SecondaryDark ='secondary-dark',
}

export enum EButtonSizes {
  Large = 'large',
  Default = 'default',
  Small = 'small',
  XSmall = 'xsmall',
}
export enum EButtonActionTypes {
  HeaderNavigation = 'headerNavigation',
  Navigation = 'navigation',
  AgeVerification = 'ageVerification',
  TriggerSignUp = 'triggerSignUp',
  TriggerLogin = 'triggerLogin',
  TriggerGoogleSignIn = 'triggerGoogleSignIn',
  TriggerEmailPasswordSignIn = 'triggerEmailPasswordSignIn',
}
