import { Component } from '@angular/core';

import { ERecaptchaTermsLinks } from 'app/enums/terms-of-service.enums';


@Component({
  selector: 'stxt-recaptcha-terms',
  standalone: true,
  imports: [],
  templateUrl: './recaptcha-terms.component.html',
  styleUrl: './recaptcha-terms.component.scss'
})
export class RecaptchaTermsComponent {
  public privacyPolicyLink: string = ERecaptchaTermsLinks.PrivacyPolicy;
  public termsOfServiceLink: string = ERecaptchaTermsLinks.TermsOfService;
}
