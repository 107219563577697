<ng-container *ngIf="creatorData">
  <div class="creator">
    <div class="creator_nav" [class.visible]="showChatCTA">
      <div class="creator_nav__start flex items-center">
        <stxt-svg-icon
          (click)="navigateBack()"
          class="creator_nav__svg"
          [iconConfig]="leftIcon"
        ></stxt-svg-icon>
        <p [class.visible]="showChatCTA" class="creator_profile__name chat-now-cta">
          {{ creatorData.name }}
        </p>
      </div>
      <div class="creator_nav__end flex items-center">
        <!--        Hidden for v1-->
        <!--        <stxt-svg-icon class="creator_nav__svg" [iconConfig]="shareIcon"></stxt-svg-icon>-->
        <!--      <stxt-svg-icon class="creator_nav__svg" [iconConfig]="moreIcon"></stxt-svg-icon>-->
        <div class="chat-now-cta" [class.visible]="showChatCTA">
          <stxt-button (click)="openChat()" [buttonConfig]="chatNowButtonConfig">
            <div class="button-content">
              <stxt-svg-icon class="chat_svg" [iconConfig]="chatIcon"></stxt-svg-icon>
              <p>{{ chatNowButtonConfig.text }}</p>
            </div>
          </stxt-button>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="creator_wrapper">
      <div class="creator_profile">
        <img class="creator_profile__img" [src]="creatorData.image" alt="creator" />
        <div class="creator_profile__card">
          <div class="relative">
            <p class="creator_profile__name">{{ creatorData.name }}</p>
            <img src="assets/icons/verified.svg" alt="verified" class="creator_profile__badge" />
          </div>
          <p class="creator_profile__description">{{ creatorData.bio }}</p>
          <div class="creator_profile__info">
            <div class="creator_profile__info-item">
              <stxt-svg-icon class="creator_svg" [iconConfig]="ageIcon"></stxt-svg-icon>
              <p>{{ creatorData.age }} years</p>
            </div>
            <div class="creator_profile__info-item">
              <stxt-svg-icon class="creator_svg" [iconConfig]="heightIcon"></stxt-svg-icon>
              <p>{{ creatorData.height }}</p>
            </div>
            <div class="creator_profile__info-item">
              <stxt-svg-icon class="creator_svg" [iconConfig]="ethnicityIcon"></stxt-svg-icon>
              <p>{{ creatorData.nationality }}</p>
            </div>
          </div>
          <div class="creator_button__desktop">
            <stxt-button (click)="openChat()" [buttonConfig]="chatNowButtonConfig">
              <div class="button-content">
                <stxt-svg-icon class="chat_svg" [iconConfig]="chatIcon"></stxt-svg-icon>
                <p>{{ chatNowButtonConfig.text }}</p>
              </div>
            </stxt-button>
          </div>
        </div>
      </div>
      <div class="creator_content">
        <stxt-masonry-gallery
          *ngIf="creatorData.content"
          [mediaContent]="creatorData.content"
          [userId]="userId"
          [creator]="creatorData"
          [postDescription]="'posts'"
        ></stxt-masonry-gallery>
      </div>
    </div>
  </div>
</ng-container>
