<div class="billing">
  <h4 class="billing_heading">Billing info</h4>
  <p class="billing_subheading">Visa and Mastercard credit cards are accepted forms of payment.</p>

  <div class="billing_items flex flex-column">
    <ng-container *ngIf="(!billingInfo || billingInfo?.length === 0) && emailVerified">
      <stxt-payment-form
        [paymentType]="EPaymentType.Billing"
        (paymentSuccessEvent)="handleUpdateBilling()"
      ></stxt-payment-form>
    </ng-container>
  </div>
  <ng-container *ngIf="billingInfo && billingInfo?.length > 0">
    <div class="new-payment_item flex flex-column gap-3">
      <ng-container *ngFor="let method of billingInfo">
        <div class="payment-methods_item flex space-between items-center">
          <p class="payment-methods_text">{{ method?.card_type + '  *' + method?.last_four }}</p>
          <div class="flex gap-2">
            <div *ngIf="method?.primary_payment_method" class="payment-methods_badge">
              <stxt-svg-icon
                class="payment-methods_svg"
                [iconConfig]="infoSvgConfig"
              ></stxt-svg-icon>
              <p class="payment-methods_badge__text">Default</p>
            </div>
            <stxt-svg-icon
              [iconConfig]="removeBillingMethodSvg"
              (click)="removeBillingMethod(method?.id)"
              (keydown.enter)="removeBillingMethod(method?.id)"
            ></stxt-svg-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
