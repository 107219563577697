<span
  *ngIf="
    ((resendCodeCountdown.countdown$ | async) < 1 && !resendCodeCountdown.isCountdownStarted) ||
    countdownAvailable !== true
  "
  class="send-code {{ usageCase }}_send-code pointer"
  (click)="sendVerifyCode(true)"
  (keydown.enter)="sendVerifyCode(true)"
  tabindex="0"
>
  Resend Code
</span>
<span
  *ngIf="countdownAvailable && resendCodeCountdown.countdown$ | async as countdown"
  class="countdown-code {{ usageCase }}_countdown"
>
  Resend in {{ countdown }}s
</span>
