export enum ESvgTypes{
  Enabled = 'enabled',
  Hovered = 'hovered',
  Focused = 'focused',
  Pressed = 'pressed',
  Disabled = 'disabled',
  Outlined = 'outlined',
  OutlinedDark = 'outlined-dark',
  Tonal = 'tonal',
  Filled = 'filled',
  ColorFilledLighter = 'color-filled-lighter',
  ColorFilledDarker = 'color-filled-darker',
  None = 'none',
}
