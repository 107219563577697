<div class="footer flex">
  <div class="footer_nav">
    <ul class="footer_nav__list">
      <li *ngFor="let footerLink of footerLinks" class="footer_nav__list-item">
        <a [href]="footerLink.url" target="_blank">{{ footerLink.title }}</a>
        <stxt-svg-icon class="footer_arrow-svg" [iconConfig]="arrowSvg"></stxt-svg-icon>
      </li>
    </ul>
  </div>
  <div class="divider"></div>
  <div class="footer_stxt-info">
    <img class="footer_img" src="../../../assets/logo/Logo_outlined.svg" alt="logo" />
    <p class="footer_rights">
      © 2024 SYNTHETIC TURING EXPERIENCE TECHNOLOGIES, ALL RIGHT RESERVED
    </p>
  </div>
</div>
