"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FeatureGate = /** @class */function () {
  function FeatureGate(gateName, value, ruleID, evaluationDetails, groupName, idType, secondaryExposures) {
    if (groupName === void 0) {
      groupName = null;
    }
    if (idType === void 0) {
      idType = null;
    }
    if (secondaryExposures === void 0) {
      secondaryExposures = [];
    }
    this.name = gateName;
    this.value = value;
    this.ruleID = ruleID !== null && ruleID !== void 0 ? ruleID : '';
    this.evaluationDetails = evaluationDetails;
    this.groupName = groupName;
    this.idType = idType;
    this.secondaryExposures = secondaryExposures;
  }
  FeatureGate.prototype.getRuleID = function () {
    return this.ruleID;
  };
  FeatureGate.prototype.getGroupName = function () {
    return this.groupName;
  };
  FeatureGate.prototype.getIDType = function () {
    return this.idType;
  };
  FeatureGate.prototype.getName = function () {
    return this.name;
  };
  FeatureGate.prototype.getValue = function () {
    return this.value;
  };
  FeatureGate.prototype.getEvaluationDetails = function () {
    return this.evaluationDetails;
  };
  FeatureGate.prototype.getSecondaryExposures = function () {
    return this.secondaryExposures;
  };
  return FeatureGate;
}();
exports.default = FeatureGate;