import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  modifyEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    if (!localPart || !domain) {
      throw new Error('Invalid email address');
    }
    const modifiedLocalPart = localPart[0] + '***';
    return `${modifiedLocalPart}@${domain}`;
  }
}
