<section class="verify-email w-full h-full relative">
  <form class="w-full h-full flex flex-column space-between" [formGroup]="verifyForm">
    <div>
      <p class="verify-email_heading">Verify email address</p>
      <p class="verify-email_subheading">Enter the 6-digit code sent to {{ email }}.</p>
      <stxt-input [inputConfig]="verifyCodeInput" formControlName="verifyCode"></stxt-input>
      <stxt-resend-code
        [usageCase]="EResendCodeUsage.Profile"
        (resendCodeSent)="isToastMessage = true"
        class="verify-email_resend"
      ></stxt-resend-code>
    </div>
    <stxt-button
      [buttonConfig]="verifyButton"
      [disabled]="verifyForm.invalid"
      (click)="verifyEmail()"
      (keydown.enter)="verifyEmail()"
      tabindex="-1"
    >
      {{ verifyButton.text }}
    </stxt-button>
  </form>
  <ng-container *ngIf="isToastMessage">
    <stxt-success-toast
      [toastHeading]="'Security code was resent'"
      [toastSubheading]="'Please check your email for another 6-digit code.'"
      (closeToast)="isToastMessage = false"
      class="verify-email_toast w-full"
    ></stxt-success-toast>
  </ng-container>
</section>
