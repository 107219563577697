import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-chat-feature-option',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './chat-feature-option.component.html',
  styleUrl: './chat-feature-option.component.scss'
})
export class ChatFeatureOptionComponent {
  @Input() tagName: string;
  @Input() isLoading: boolean;
  sendIcon: ISvgConfig = { name: 'interactive', fill: ESvgTypes.None };
}
