import { Component, Input, OnInit } from '@angular/core';
import { CommonModule }             from '@angular/common';

import { first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import {
  CheckoutAdditionalInfoComponent
}                           from 'app/components/payment/checkout/checkout-additional-info/checkout-additional-info.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IBillingHistory, ITransactionItem } from 'app/interfaces/profile.interfaces';
import { ISvgConfig }                        from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-transaction-info',
  standalone: true,
  imports: [ CommonModule, CheckoutAdditionalInfoComponent, SvgIconComponent ],
  templateUrl: './transaction-info.component.html',
  styleUrl: './transaction-info.component.scss'
})
export class TransactionInfoComponent implements OnInit {
  @Input() transaction: IBillingHistory;
  @Input() creator: string;
  public cardSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'card' };
  public dateSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'date' };
  public checkSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'check-circle' };
  public hashtagSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'hashtag' };
  public transactionLoaded: boolean = false;
  public transactionItem: ITransactionItem;

  constructor(public profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.profileService.getUserTransactionItem(this.transaction.id)
      .pipe(first())
      .subscribe({
        next: res => {
          this.transactionLoaded = true;
          this.transactionItem = res;
        },
        error: err => {
          console.log(err);
        }
      })
  }

}
