<div
  *ngIf="conversation.thumbnail_image.length > 0"
  (click)="openChat()"
  (keydown.enter)="openChat()"
  class="chat-item flex w-full items-center pointer"
  tabindex="0"
>
  <div class="chat-item_main flex items-center">
    <div class="chat-item_photo flex">
      <ng-container *ngIf="conversation.thumbnail_image.length">
        <img class="chat-item_photo-user" [src]="conversation.thumbnail_image" alt="creator-img" />
      </ng-container>
      <ng-container *ngIf="!conversation.thumbnail_image.length">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
        >
          <circle cx="28" cy="28" r="28" fill="#7B0072" />
        </svg>
      </ng-container>
    </div>

    <div class="chat-item_main__info">
      <p class="chat-item_main__name">{{ conversation.creator_name }}</p>
      <p class="chat-item_main__message">{{ conversation.last_message }}</p>
    </div>
  </div>
  <div class="chat-item_info flex flex-column">
    <div class="chat-item_info__date flex items-center">
      <p>{{ formattedDate }}</p>
      <stxt-svg-icon class="nav_icon" [iconConfig]="navIcon"></stxt-svg-icon>
    </div>
    <!--    TODO - NO needed for v1-->
    <!--    <div class="chat-item_info__new-message flex flex-column items-center w-fit-content">-->
    <!--      3-->
    <!--    </div>-->
  </div>
</div>
