<div class="payment-method">
  <div>
    <div class="payment-method_head">
      <stxt-svg-icon (click)="goBack()" [iconConfig]="arrowSvg" class="checkout_icon"></stxt-svg-icon>
      <p class="checkout_heading">Select payment method</p>
      <div></div>
    </div>
    <div class="payment-method_item">
      <input type="radio" class="btn-check payment_radio-btn" name="options-base" id="option7" autocomplete="off">
      <div>
        <p class="payment-method_item__card">Visa *1234</p>
        <p class="payment-method_item__address">51 Camden St, Toronto, ON M5V 1V2, Canada</p>
      </div>
    </div>
    <div class="payment-method_item">
      <input type="radio" class="btn-check payment_radio-btn" name="options-base" id="option8" autocomplete="off">
      <div>
        <p class="payment-method_item__card">Visa *1234</p>
        <p class="payment-method_item__address">51 Camden St, Toronto, ON M5V 1V2, Canada</p>
      </div>
    </div>
  </div>
  <div class="payment-method_footer">
    <stxt-button (click)="addNewPayment()" [buttonConfig]="addMethodBtn">{{addMethodBtn.text}}</stxt-button>
  </div>
</div>
