import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fade', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate('0.3s ease-in-out', style({ opacity: 1 }))
  ])
]);

export const fadeAnimationSequence = trigger('appearingItems', [
  transition('void => *', [
    query('.fade', [
      style({
        opacity: 0
      }),
      stagger(100, [animate('0.3s ease-in', style({ opacity: 1 }))])
    ])
  ])
]);
