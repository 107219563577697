import { Component } from '@angular/core';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { ETermsOfService } from 'app/enums/terms-of-service.enums';


@Component({
  selector: 'stxt-checkout-additional-info',
  standalone: true,
  imports: [ SvgIconComponent ],
  templateUrl: './checkout-additional-info.component.html',
  styleUrl: './checkout-additional-info.component.scss'
})
export class CheckoutAdditionalInfoComponent {
  public infoSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'info' };
  public readonly ETermsOfService = ETermsOfService;
}
