export enum EPaymentType {
  Purchase = 'purchase',
  Subscription = 'subscription',
  Billing = 'billing'
}

export enum EPaymentStatus {
  Success = 'success',
  Error = 'error',
  Close = 'close'
}
