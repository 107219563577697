import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

/**
 * This interceptor is a temporary solution to provide basic authentication for environment protection.
 * It is intended to prevent crawling and unauthorized access to the application in staging and development environments.
 * Please note that this is a very basic implementation and should be replaced with a more robust authentication mechanism
 * once the back-end is fully involved. The current approach is a placeholder to ensure some level of protection until
 * a more comprehensive solution is implemented.
 */

@Injectable()
export class EnvironmentProtectionInterceptor implements HttpInterceptor {
  private readonly USERNAME = 'stxt';
  private readonly PASSWORD = 'dev';
  private readonly STORAGE_KEY = 'env_protection_authenticated';

  private get isAuthenticated(): boolean {
    return localStorage.getItem(this.STORAGE_KEY) === 'true';
  }

  private set isAuthenticated(value: boolean) {
    if (value) {
      localStorage.setItem(this.STORAGE_KEY, 'true');
    } else {
      localStorage.removeItem(this.STORAGE_KEY);
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      (environment.serviceEnvironment === 'staging' ||
        environment.serviceEnvironment === 'development') &&
      !this.isAuthenticated
    ) {
      this.promptForCredentials();
    }

    return next.handle(request);
  }

  private promptForCredentials(): void {
    const credentials = window.prompt('Please enter credentials (username:password)', '');
    if (
      credentials &&
      credentials.split(':')[0] === this.USERNAME &&
      credentials.split(':')[1] === this.PASSWORD
    ) {
      this.isAuthenticated = true;
    } else {
      alert('Invalid credentials');
      this.promptForCredentials();
    }
  }

  public static clearCredentials(): void {
    localStorage.removeItem('env_protection_authenticated');
  }
}
