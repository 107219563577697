"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StatsigAsyncStorage = /** @class */function () {
  function StatsigAsyncStorage() {}
  StatsigAsyncStorage.getItemAsync = function (key) {
    var _a;
    if (StatsigAsyncStorage.asyncStorage) {
      return (_a = StatsigAsyncStorage.asyncStorage.getItem(key)) !== null && _a !== void 0 ? _a : null;
    }
    return Promise.resolve(null);
  };
  StatsigAsyncStorage.setItemAsync = function (key, value) {
    if (StatsigAsyncStorage.asyncStorage) {
      return StatsigAsyncStorage.asyncStorage.setItem(key, value);
    }
    return Promise.resolve();
  };
  StatsigAsyncStorage.removeItemAsync = function (key) {
    if (StatsigAsyncStorage.asyncStorage) {
      return StatsigAsyncStorage.asyncStorage.removeItem(key);
    }
    return Promise.resolve();
  };
  return StatsigAsyncStorage;
}();
exports.default = StatsigAsyncStorage;