<div class="payment-succeed">
  <div class="payment-succeed_modal-head">
    <stxt-svg-icon (click)="close()" [iconConfig]="closeSvg" class="checkout_icon"></stxt-svg-icon>
  </div>
  <h4 class="payment-succeed_heading">Thank you</h4>
  <p class="payment-succeed_subheading">
    Your {{ paymentType }} was successfully processed
  </p>
  <img class="payment-succeed_image" src="../../../../assets/d2c-images/payment-succeed.png" alt="payment-succeed"/>
  <stxt-button (click)="close()" class="payment-succeed_btn" [buttonConfig]="continueBtn">
    {{ continueBtn.text }}
  </stxt-button>
</div>
