/* eslint-disable */
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  FormGroupDirective,
  ControlContainer,
  ReactiveFormsModule
} from '@angular/forms';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

import { IInputCustomConfig } from '../../interfaces/input.interfaces';
import { ISvgConfig } from '../../interfaces/svg.interfaces';

import { EInputCustomType, EInputUsage } from '../../enums/input.enums';
import { ESvgTypes } from '../../enums/svg.enums';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

export const INPUT_VALIDATION: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'stxt-input',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [INPUT_VALUE_ACCESSOR, INPUT_VALIDATION],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputComponent implements OnInit {
  @Input() inputConfig: IInputCustomConfig;
  @Input() formControlName: string;
  @Input() hintText?: string;
  isError: boolean = false;
  formControl: FormControl;
  public EInputCustomType = EInputCustomType;
  public value: string;
  public disableState = false;
  public hide: boolean = true;
  public passwordInvisibleIcon: ISvgConfig = {
    name: 'invisible',
    fill: ESvgTypes.None
  };
  public passwordVisibleIcon: ISvgConfig = {
    name: 'visibility_on',
    fill: ESvgTypes.None
  };
  public errorIcon: ISvgConfig = {
    name: 'error',
    fill: ESvgTypes.None
  };
  protected readonly EInputUsage = EInputUsage;
  private hasFocusedOut: boolean = false;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit() {
    this.formControl = this.controlContainer.control.get(this.formControlName) as FormControl;
    this.formControl.valueChanges.subscribe(() => this.change());
  }

  writeValue(value: string | null): void {
    if (value === null) {
      this.value = '';
      return;
    }
    this.value = value;
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  /**
   * Handles the focusout event for the input element.
   * This function is triggered when the input element loses focus.
   * Checks if the input has any validation errors and updates the `isError` flag accordingly.
   * @param control - The form control or event related to the input element. It can be a FormControl or any object.
   */
  focusOut(control: FormControl): void {
    this.hasFocusedOut = true;
    this.isError = control.invalid && control.touched;
    this.formControl.markAsTouched();
  }

  change(): void {
    if (this.hasFocusedOut === true) {
      this.isError = this.formControl.invalid && this.formControl.touched;
    }
  }

  changeType(hide: boolean): void {
    this.hide = hide;
  }

  validate(control: AbstractControl): null | boolean {
    this.isError = control.errors !== null && control.touched && !!control.errors;
    return this.isError;
  }

  private onChange: (p: any) => void = () => {};

  private onTouched: (p: any) => void = () => {};
}
