import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { first, interval, map, Observable, take } from 'rxjs';

import { AuthService } from '../../../auth/auth.service';

import { EVerifyAttributeName } from '../../../auth/auth.enums';
import { ELocalStorageKey, EResendCodeUsage } from '../../enums/shared.enums';

@Component({
  selector: 'stxt-resend-code',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './resend-code.component.html',
  styleUrl: './resend-code.component.scss'
})
export class ResendCodeComponent implements OnInit {
  @Input() usageCase: EResendCodeUsage;
  @Output() resendCodeSent: EventEmitter<void> = new EventEmitter<void>();
  resendCodeCountdown = {
    countdown$: new Observable<number>(),
    countdownDuration: 60,
    countdownKey: ELocalStorageKey.CountdownVerificationStartTime,
    isCountdownStarted: false
  };
  isVerifySent = localStorage.getItem(ELocalStorageKey.VerifySent);
  countdownAvailable: boolean;
  cognitoAccessToken = localStorage.getItem(ELocalStorageKey.CognitoAccessToken);

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    switch (this.usageCase) {
      case EResendCodeUsage.Chat:
        this.checkExistingCountdown();
        this.countdownAvailable = true;
        break;
      case EResendCodeUsage.Profile:
        this.checkProfileVerificationState();
        break;
    }
  }

  checkProfileVerificationState(): void {
    if (this.isVerifySent === 'true') {
      this.checkExistingCountdown();
      this.countdownAvailable = true;
    } else {
      this.sendVerifyCode(false);
      localStorage.setItem(ELocalStorageKey.VerifySent, 'true');
    }
  }

  checkExistingCountdown(): void {
    const startTime = localStorage.getItem(this.resendCodeCountdown.countdownKey);
    const now = new Date().getTime();

    if (!startTime) return;
    const elapsedSeconds = Math.floor((now - Number(startTime)) / 1000);
    const remainingSeconds = this.resendCodeCountdown.countdownDuration - elapsedSeconds;

    remainingSeconds > 0
      ? this.startCountdown(remainingSeconds)
      : localStorage.removeItem(this.resendCodeCountdown.countdownKey);
  }

  sendVerifyCode(resend: boolean): void {
    const verifyData = {
      attribute_name: EVerifyAttributeName.EMAIL,
      cognito_access_token: this.cognitoAccessToken
    };
    this.authService
      .resendVerifyCode(verifyData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.handleCheckOnResendCode(resend);
        },
        error: err => {
          if (err.status === 200) {
            this.handleCheckOnResendCode(resend);
          }
        }
      });
  }

  handleCheckOnResendCode(resend: boolean): void {
    this.startOrResumeCountdown();
    this.countdownAvailable = resend;
    if (!resend) return;
    this.resendCodeSent.emit();
  }

  startOrResumeCountdown(): void {
    if (this.resendCodeCountdown.isCountdownStarted) return;
    this.resendCodeCountdown.isCountdownStarted = true;
    const now = new Date().getTime();
    localStorage.setItem(this.resendCodeCountdown.countdownKey, now.toString());
    this.startCountdown(this.resendCodeCountdown.countdownDuration);
  }

  startCountdown(seconds: number): void {
    this.resendCodeCountdown.countdown$ = interval(1000).pipe(
      take(seconds + 1),
      map(value => seconds - value)
    );

    this.resendCodeCountdown.countdown$.subscribe({
      complete: () => {
        localStorage.removeItem(this.resendCodeCountdown.countdownKey);
        this.resendCodeCountdown.isCountdownStarted = false;
      }
    });
  }
}
