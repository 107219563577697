import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

import WaveSurfer from 'wavesurfer.js';

import { AnalyticsService } from '../../../../shared/services/analytics.service';

import { TimeFormatPipe } from '../../../../pipes/time-format.pipe';

import { EChatStates } from 'app/enums/conversation';

@Component({
  selector: 'stxt-message-audio',
  standalone: true,
  imports: [CommonModule, TimeFormatPipe],
  templateUrl: './message-audio.component.html',
  styleUrl: './message-audio.component.scss'
})
export class MessageAudioComponent implements AfterViewInit {
  @ViewChild('wave') container: ElementRef;
  /* eslint-disable  @typescript-eslint/no-explicit-any */ //temporary fix
  @Input() safeUrl: any;
  @Input() userId: string;
  @Input() creatorId: string;
  @Input() creatorName: string;
  @Output() audioGenerated: EventEmitter<void> = new EventEmitter<void>();
  waveSurfer: WaveSurfer;
  play = false;
  trackLoadTime: number;
  renderingTime: number;
  duration: number;
  timeLeft: number;
  totalTime: number;
  srcForm: FormGroup;
  isAudioQueried = false;

  constructor(public analyticsService: AnalyticsService) {}

  /**
   * Initializes the WaveSurfer audio visualization library after the view has been initialized.
   * This function sets up the WaveSurfer instance with specific configurations, including the container,
   * wave color, progress color, height, bar gap, bar radius, and the audio URL.
   * It also sets up event listeners to handle audio readiness and audio processing events.
   * Once the audio is ready, it updates the component's state to reflect the total duration of the audio.
   * During audio playback, it continuously updates the time left.
   */
  ngAfterViewInit(): void {
    // Create WaveSurfer instance with specified configurations
    this.waveSurfer = WaveSurfer.create({
      container: this.container.nativeElement,
      waveColor: '#8C768E',
      progressColor: '#DDC9DE',
      height: 40,
      barGap: 2,
      barRadius: 2,
      barWidth: 2,
      url: this.safeUrl.changingThisBreaksApplicationSecurity
    });

    // Event listener for when the audio is ready to be played
    this.waveSurfer.on('ready', () => {
      this.isAudioQueried = true;
      this.totalTime = this.waveSurfer.getDuration();
      this.timeLeft = Math.round(this.waveSurfer.getDuration());
    });

    // Event listener for audio processing during playback
    this.waveSurfer.on('audioprocess', () => {
      if (this.waveSurfer.isPlaying()) {
        const currentTime = this.waveSurfer.getCurrentTime();
        this.timeLeft = Math.round(this.totalTime - currentTime);
      } else {
        this.play = false;
      }
    });
  }

  onPlayButtonClick(): void {
    this.waveSurfer.play();
    this.play = true;
    this.analyticsService.voiceNotePlayed(
      this.userId,
      this.creatorName,
      EChatStates.FREEFLOW,
      this.creatorId
    );
  }

  onPauseButtonClick(): void {
    this.waveSurfer.pause();
    this.play = false;
  }
}
