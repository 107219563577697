import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule }                     from '@angular/common';

import { BehaviorSubject, first } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { SvgIconComponent }        from 'app/shared/components/svg-icon/svg-icon.component';
import { BillingHistoryComponent } from './billing-history/billing-history.component';
import { SwitchComponent }         from 'app/shared/components/switch/switch.component';

import { IBillingHistory, IUserSubscription } from 'app/interfaces/profile.interfaces';
import { ISvgConfig }                         from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes }                                    from 'app/shared/enums/svg.enums';
import { ESubscriptionManagerPart, ESubscriptionState } from 'app/enums/purchases.enums';
import { EProfileSectionTitle }                         from 'app/enums/profile.enums';

@Component({
  selector: 'stxt-subscription-management',
  standalone: true,
  imports: [ CommonModule, SvgIconComponent, BillingHistoryComponent, SwitchComponent ],
  templateUrl: './subscription-management.component.html',
  styleUrl: './subscription-management.component.scss'
})
export class SubscriptionManagementComponent implements OnInit {
  @Input() selectedSubscription: IUserSubscription;
  @Output() autoRenewChange: EventEmitter<void> = new EventEmitter<void>();
  public rightNavSvg: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };
  public transactionsHistory: IBillingHistory[];
  public transactionsLoaded: boolean = false;
  protected readonly ESubscriptionManagerPart = ESubscriptionManagerPart;
  protected readonly SubscriptionState = ESubscriptionState;
  protected readonly EProfileSectionTitle = EProfileSectionTitle;
  public profileSettingsTitle$: BehaviorSubject<string> = this.profileService.profileActiveSection$;

  constructor(public profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.getTransactions();
  }

  getTransactions(): void {
    this.profileService.getUserTransactionsByCreatorId(this.selectedSubscription.creator_id)
      .pipe(first())
      .subscribe(res => {
        this.transactionsHistory = res;
        this.transactionsLoaded = true;
      });
  }

  onStateChange(state: boolean): void {
    const renewState = state ? "on" : "off";
    const payload = {
      auto_renew: renewState,
      subscription_id: this.selectedSubscription.id
    }
    this.profileService.updateSubscription(payload)
      .pipe(first())
      .subscribe({
        next: () => {
          this.autoRenewChange.emit();
        },
        error: (err) => {
          console.log(err)
        }
      });
  }

  openBillingHistory(): void {
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Billing_History);
  }
}
