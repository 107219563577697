<div>
  <div class="checkout_info">
    <div class="checkout_info__row flex">
      <p *ngIf="paymentType === EPaymentType.Purchase">{{ creator }} AI media pack</p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        Monthly subscription - {{ creator }} AI
      </p>
    </div>
    <div class="checkout_info__row flex">
      <p>Subtotal</p>
      <p *ngIf="paymentType === EPaymentType.Purchase">
        {{ purchaseData?.price / 100 | currency }}
      </p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        {{ subscriptionData?.price / 100 | currency }}
      </p>
    </div>
    <div class="checkout_info__row flex">
      <p>Processing fees</p>
      <p>$0.00</p>
    </div>
    <div class="checkout_total flex">
      <p>Total</p>
      <p *ngIf="paymentType === EPaymentType.Purchase">
        {{ purchaseData?.price / 100 | currency }}
      </p>
      <p *ngIf="paymentType === EPaymentType.Subscription">
        {{ subscriptionData?.price / 100 | currency }}
      </p>
    </div>
  </div>
  <ng-container *ngIf="firstCheckOut">
    <stxt-code-verify
      *ngIf="verificationState.isVerifying"
      [email]="userProfile?.email"
      [verifiedState]="verificationState.isVerified"
      [isVerifyingState]="verificationState.isVerifying"
      (userVerified)="setUserVerified()"
    ></stxt-code-verify>

    <stxt-payment-form
      *ngIf="!billingData && verificationState.isVerified"
      [creator]="creator"
      [creatorId]="creatorId"
      [firstCheckOut]="firstCheckOut"
      [paymentType]="paymentType"
      [purchaseData]="purchaseData"
      [subscriptionData]="subscriptionData"
      [wsConnectionId]="wsConnectionId"
      (formValidityChange)="onFormValidityChange($event)"
      (paymentSuccessEvent)="handlePaymentSuccess($event)"
      (paymentFinishEvent)="showPaymentProcessing = false"
    ></stxt-payment-form>
  </ng-container>
  <ng-container *ngIf="!firstCheckOut || billingData">
    <p class="checkout_payment-method__heading">Payment method</p>
    <div *ngFor="let billingItem of billingData" class="checkout_payment-method__item">
      <div>
        <p class="checkout_payment-method__card">
          {{ billingItem.card_type }} *{{ billingItem.last_four }}
        </p>
        <p class="checkout_payment-method__address">{{ billingItem.postal_code }}</p>
      </div>
      <!--      <stxt-svg-icon [iconConfig]="arrowSvg" class="checkout_icon"></stxt-svg-icon>-->
    </div>
  </ng-container>
  <stxt-checkout-additional-info></stxt-checkout-additional-info>
</div>
<div class="checkout_footer">
  <form [formGroup]="termsForm">
    <div class="checkout_footer__agreement">
      <input
        class="form-check-input"
        formControlName="acceptTerms"
        id="flexCheckDefault"
        type="checkbox"
      />
      <p>
        You acknowledge that you have read and agree to
        <a target="_blank" href="{{ ETermsOfService.TermsOfUse }}" rel="noopener">
          STXT’s Terms of Use
        </a>
        .
      </p>
    </div>
  </form>
  <stxt-button
    [buttonConfig]="confirmBtn"
    [disabled]="termsForm.invalid || !verificationState.isVerified"
    (click)="confirmPayment()"
  >
    {{ confirmBtn.text }}
  </stxt-button>
  <stxt-recaptcha-terms></stxt-recaptcha-terms>
</div>
<ng-container *ngIf="showPaymentProcessing">
  <div class="loading_wrapper">
    <div class="loading_wrapper__head flex items-center">
      <p>Check out</p>
    </div>
    <stxt-loading [loadingText]="ELoadingTexts.ProcessingPayment"></stxt-loading>
  </div>
</ng-container>
