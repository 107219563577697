import { Injectable } from '@angular/core';

import statsig from 'statsig-js';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsigService {
  private initialized = false;
  private statsigKey: string = environment.statsigKey;

  async initializeStatsig(userId: string): Promise<void> {
    try {
      if (!this.initialized) {
        await statsig.initialize(
          this.statsigKey,
          { userID: userId },
          { environment: { tier: environment.serviceEnvironment } }
        );
        this.initialized = true;
      }
    } catch (error) {
      console.error('Failed to initialize Statsig:', error);
    }
  }

  async checkFeatureGate(featureName: string): Promise<boolean> {
    if (!this.initialized) {
      throw new Error('Statsig is not initialized yet.');
    }
    return statsig.checkGate(featureName);
  }
}
