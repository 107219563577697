<div class="preferences">
  <h4 class="preferences_heading">Preferences</h4>
  <div class="preferences_items flex flex-column">
    <div class="preferences_item">
      <p class="preferences_item__heading">How do you identify?</p>
      <p class="preferences_item__subheading">Select one:</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option *ngFor="let option of identifyOptions"
                             (click)="selectGender(option)"
                             [option]="option">
        </stxt-profile-option>
      </div>
    </div>
    <div class="preferences_item">
      <p class="preferences_item__heading">Who are you interested in?</p>
      <p class="preferences_item__subheading">Select all that apply:</p>
      <div class="preferences_item__options flex">
        <stxt-profile-option *ngFor="let option of interestsOptions"
                             (click)="toggleInterest(option)"
                             [option]="option">
        </stxt-profile-option>
      </div>
    </div>
  </div>
</div>
