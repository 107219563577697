"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function Polyfill() {
  if (!Object.fromEntries) {
    // @ts-ignore polyfill from https://github.com/tc39/proposal-object-from-entries/blob/main/polyfill.js
    Object.fromEntries = function (iter) {
      var obj = {};
      for (var _i = 0, iter_1 = iter; _i < iter_1.length; _i++) {
        var pair = iter_1[_i];
        if (Object(pair) !== pair) {
          throw new TypeError('iterable for fromEntries should yield objects');
        }
        // Consistency with Map: contract is that entry has "0" and "1" keys, not
        // that it is an array or iterable.
        var key = pair[0],
          val = pair[1];
        Object.defineProperty(obj, key, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: val
        });
      }
      return obj;
    };
  }
}
exports.default = Polyfill;