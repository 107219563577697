<section
  *ngIf="(profileSettingsTitle$ | async) === EProfileSectionTitle.View_Billing_History"
  class="billing-history flex flex-column"
>
  <div
    *ngFor="let billingItem of billingHistoryItems"
    class="billing-history_item flex space-between"
  >
    <p>
      <span
        [ngClass]="{
          paid: billingItem.state === 'paid',
          canceled: billingItem.state === 'canceled'
        }"
        class="billing-history_item__status"
      ></span>
      {{ billingItem.state | titlecase }}
      <span *ngIf="billingItem.state === 'canceled'">payment</span>
      {{ billingItem.total | currency }}
      total on {{ billingItem.closed_at | date: 'MM/dd/yyyy' }}
    </p>
    <stxt-svg-icon
      [iconConfig]="rightNavSvg"
      (click)="openTransactionInfo(billingItem)"
      class="billing-history_item__svg"
    ></stxt-svg-icon>
  </div>
</section>

<ng-container
  *ngIf="(profileSettingsTitle$ | async) === EProfileSectionTitle.View_Transaction_Info"
>
  <stxt-transaction-info
    [creator]="creator"
    [transaction]="selectedTransaction"
  ></stxt-transaction-info>
</ng-container>
