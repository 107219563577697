import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { DiscoverService } from '../../shared/services/discover.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthService } from 'app/auth/auth.service';
import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';

import { AuthFormComponent } from '../../shared/components/auth-form/auth-form.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { DiscoverItemComponent } from '../../components/discover-item/discover-item.component';
import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from '../../shared/interfaces/button.interfaces';
import { IDiscoverItem, IDiscoverResponse } from '../../interfaces/discover.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EButtonActionTypes, EButtonSizes, EButtonTypes } from '../../shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-home-page',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    NgbCarouselModule,
    AuthFormComponent,
    ButtonComponent,
    DiscoverItemComponent,
    SvgIconComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  providers: [NgbCarouselConfig, ReCaptchaV3Service]
})
export class HomePageComponent implements OnInit {
  public isLander: boolean = false;
  public discoverData: IDiscoverItem[];
  public userId: string = localStorage.getItem('userId');
  public chatNavButton: IButtonConfig = {
    text: 'Chat now',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  public heatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'mode_heat' };
  protected showIndicators: boolean;
  public creatorInfo: { creator_name: string; thumbnail_image: string };
  public user = JSON.parse(localStorage.getItem('user'));

  constructor(
    private discoverService: DiscoverService,
    public router: Router,
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private config: NgbCarouselConfig,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private navigationIntentService: NavigationIntentService
  ) {
    config.wrap = true;
    config.interval = 3000;
    config.keyboard = false;
    config.pauseOnHover = true;
    config.animation = true;
  }

  ngOnInit(): void {
    this.fetchDiscoverData();
  }

  fetchDiscoverData(): void {
    this.discoverService
      .getDiscoverData()
      .pipe(first())
      .subscribe({
        next: (response: IDiscoverResponse) => {
          this.discoverData = response.creators.sort((a, b) => {
            const aReady = a.ready === undefined ? false : a.ready;
            const bReady = b.ready === undefined ? false : b.ready;
            return aReady === bReady ? 0 : aReady ? -1 : 1;
          });
          this.showIndicators = this.discoverData.filter(item => item?.featured).length > 1;
        },
        error: error => {
          console.error('Error fetching data:', error);
        }
      });
  }

  async navigateToChat(clickText: string, creator: IDiscoverItem): Promise<void> {
    this.reCaptchaV3Service
      .execute('chat_now_action')
      .pipe(first())
      .subscribe({
        next: async () => {
          await this.processChatNavigation(clickText, creator);
        },
        error: error => {
          console.error('reCAPTCHA execution error:', error);
        }
      });
  }

  private async processChatNavigation(clickText: string, creator: IDiscoverItem): Promise<void> {
    this.creatorInfo = { creator_name: creator.name, thumbnail_image: creator.image };
    await this.authService.checkUserCredentials();
    localStorage.setItem('backUrl', '/');
    localStorage.setItem('creatorInfo', JSON.stringify(this.creatorInfo));
    this.userId = localStorage.getItem('userId');

    const pageName = 'Home';
    this.analyticsService.click(
      clickText,
      `/chat/${creator.id}`,
      pageName,
      EButtonActionTypes.Navigation,
      `/chat/${creator.id}`
    );
    this.navigationIntentService.setIntent(`/chat/${creator.id}`);
    this.router.navigateByUrl(`/chat/${creator.id}`);
  }

  closeAuth(): void {
    this.isLander = false;
  }
}
