import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-subscription-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, SvgIconComponent],
  templateUrl: './subscription-modal.component.html',
  styleUrl: './subscription-modal.component.scss'
})
export class SubscriptionModalComponent {
  @Input() creatorData: { creator_name: string; thumbnail_image: string };
  @Input() subscriptionPrice: number;
  @Output() openPayment: EventEmitter<void> = new EventEmitter<void>();
  public closeSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'close' };
  public checkSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'done' };
  public subscribeBtn: IButtonConfig = {
    text: 'Subscribe now',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };

  constructor(public activeModal: NgbActiveModal) {}

  openPaymentEvent(): void {
    this.activeModal.close('payment');
  }
}
