<ng-container *ngIf="media.type === 'voice'">
  <div class="creator_starter-block">
    <stxt-media-audio [media]="media.value"></stxt-media-audio>
  </div>
</ng-container>

<ng-container *ngIf="media.type === 'video'">
  <stxt-media-video
    [media]="media.value"
    [mediaId]="media.media_id"
    [videoType]="ECreatorVideoTypes.Preview"
  ></stxt-media-video>
</ng-container>

<ng-container *ngIf="media.type === 'text'">
  <div class="creator_starter-block">
    <p class="creator_starter-block__head"></p>
    <p class="creator_starter-block__body">{{ media.value }}</p>
    <div class="creator_starter-block__button">
      <stxt-button (click)="openChat()" [buttonConfig]="chatButtonConfig">
        <div class="button-content flex items-center">
          <stxt-svg-icon class="chat_svg" [iconConfig]="chatIcon"></stxt-svg-icon>
          <p>{{ chatButtonConfig.text }}</p>
        </div>
      </stxt-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="media.type === 'image'">
  <div class="creator_media__image">
    <img src="{{ media.value }}" alt="{{ media.value }}" />
  </div>
</ng-container>
