"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifySDKKeyUsed = void 0;
var Errors_1 = require("../Errors");
var Hashing_1 = require("./Hashing");
function verifySDKKeyUsed(json, sdkKey, errorBoundary) {
  var hashedSDKKeyUsed = json === null || json === void 0 ? void 0 : json.hashed_sdk_key_used;
  if (hashedSDKKeyUsed != null && hashedSDKKeyUsed !== (0, Hashing_1.djb2Hash)(sdkKey !== null && sdkKey !== void 0 ? sdkKey : '')) {
    errorBoundary.logError('verifySDKKeyUsed', new Errors_1.StatsigSDKKeyMismatchError('The SDK key provided does not match the one used to generate values.'));
    return false;
  }
  return true;
}
exports.verifySDKKeyUsed = verifySDKKeyUsed;