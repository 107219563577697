export enum ECreatorMediaTypes {
  Video = 'video',
  Voice = 'voice',
  Text = 'text',
  Image = 'image'
}

export enum ECreatorVideoTypes {
  Fullscreen = 'fullscreen',
  Preview = 'preview'
}
