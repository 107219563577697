"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StatsigRuntime = /** @class */function () {
  function StatsigRuntime() {}
  StatsigRuntime.encodeInitializeCall = true;
  return StatsigRuntime;
}();
exports.default = StatsigRuntime;