"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogLevel = exports.INIT_TIMEOUT_DEFAULT_MS = void 0;
var DEFAULT_FEATURE_GATE_API = 'https://featureassets.org/v1/';
var DEFAULT_EVENT_LOGGING_API = 'https://prodregistryv2.org/v1/';
var DEFAULT_INIT_NETWORK_RETRIES = 3;
exports.INIT_TIMEOUT_DEFAULT_MS = 3000;
var LogLevel;
(function (LogLevel) {
  LogLevel["NONE"] = "NONE";
  LogLevel["INFO"] = "INFO";
  LogLevel["DEBUG"] = "DEBUG";
  LogLevel["WARN"] = "WARN";
  LogLevel["ERROR"] = "ERROR";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
var StatsigSDKOptions = /** @class */function () {
  function StatsigSDKOptions(options) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    if (options == null) {
      options = {};
    }
    var api = (_a = options.api) !== null && _a !== void 0 ? _a : DEFAULT_FEATURE_GATE_API;
    this.api = api.endsWith('/') ? api : api + '/';
    this.disableCurrentPageLogging = (_b = options.disableCurrentPageLogging) !== null && _b !== void 0 ? _b : false;
    this.environment = (_c = options.environment) !== null && _c !== void 0 ? _c : null;
    this.loggingIntervalMillis = this.normalizeNumberInput(options.loggingIntervalMillis, {
      default: 10000,
      min: 1000,
      max: 60000
    });
    this.loggingBufferMaxSize = this.normalizeNumberInput(options.loggingBufferMaxSize, {
      default: 100,
      min: 2,
      max: 500
    });
    this.disableNetworkKeepalive = (_d = options.disableNetworkKeepalive) !== null && _d !== void 0 ? _d : false;
    this.initRequestRetries = (_e = options.initRequestRetries) !== null && _e !== void 0 ? _e : DEFAULT_INIT_NETWORK_RETRIES;
    this.overrideStableID = (_f = options.overrideStableID) !== null && _f !== void 0 ? _f : null;
    this.localMode = (_g = options.localMode) !== null && _g !== void 0 ? _g : false;
    this.initTimeoutMs = options.initTimeoutMs && options.initTimeoutMs >= 0 ? options.initTimeoutMs : exports.INIT_TIMEOUT_DEFAULT_MS;
    this.disableErrorLogging = (_h = options.disableErrorLogging) !== null && _h !== void 0 ? _h : false;
    this.disableAutoMetricsLogging = (_j = options.disableAutoMetricsLogging) !== null && _j !== void 0 ? _j : false;
    this.initializeValues = (_k = options.initializeValues) !== null && _k !== void 0 ? _k : null;
    var eventLoggingApi = (_m = (_l = options.eventLoggingApi) !== null && _l !== void 0 ? _l : options.api) !== null && _m !== void 0 ? _m : DEFAULT_EVENT_LOGGING_API;
    this.eventLoggingApi = eventLoggingApi.endsWith('/') ? eventLoggingApi : eventLoggingApi + '/';
    this.prefetchUsers = (_o = options.prefetchUsers) !== null && _o !== void 0 ? _o : [];
    this.disableLocalStorage = (_p = options.disableLocalStorage) !== null && _p !== void 0 ? _p : false;
    this.initCompletionCallback = (_q = options.initCompletionCallback) !== null && _q !== void 0 ? _q : null;
    this.updateCompletionCallback = (_r = options.updateUserCompletionCallback) !== null && _r !== void 0 ? _r : null;
    this.disableDiagnosticsLogging = (_s = options.disableDiagnosticsLogging) !== null && _s !== void 0 ? _s : false;
    this.logLevel = (_t = options === null || options === void 0 ? void 0 : options.logLevel) !== null && _t !== void 0 ? _t : LogLevel.NONE;
    this.logger = (_u = options === null || options === void 0 ? void 0 : options.logger) !== null && _u !== void 0 ? _u : console;
    this.ignoreWindowUndefined = (_v = options === null || options === void 0 ? void 0 : options.ignoreWindowUndefined) !== null && _v !== void 0 ? _v : false;
    this.fetchMode = (_w = options.fetchMode) !== null && _w !== void 0 ? _w : 'network-only';
    this.disableLocalOverrides = (_x = options === null || options === void 0 ? void 0 : options.disableLocalOverrides) !== null && _x !== void 0 ? _x : false;
    this.gateEvaluationCallback = (_y = options === null || options === void 0 ? void 0 : options.gateEvaluationCallback) !== null && _y !== void 0 ? _y : null;
    this.userPersistentStorage = (_z = options === null || options === void 0 ? void 0 : options.userPersistentStorage) !== null && _z !== void 0 ? _z : null;
    this.disableAllLogging = (_0 = options.disableAllLogging) !== null && _0 !== void 0 ? _0 : false;
    this.setLoggingCopy(options);
    this.disableHashing = (_1 = options.disableHashing) !== null && _1 !== void 0 ? _1 : false;
    this.evaluationCallback = (_2 = options.evaluationCallback) !== null && _2 !== void 0 ? _2 : null;
  }
  StatsigSDKOptions.prototype.setLoggingCopy = function (options) {
    if (options == null || this.loggingCopy != null) {
      return;
    }
    var loggingCopy = {};
    Object.entries(options).forEach(function (_a) {
      var _b, _c;
      var option = _a[0],
        value = _a[1];
      var valueType = typeof value;
      switch (valueType) {
        case 'number':
        case 'bigint':
        case 'boolean':
          loggingCopy[String(option)] = value;
          break;
        case 'string':
          if (value.length < 50) {
            loggingCopy[String(option)] = value;
          } else {
            loggingCopy[String(option)] = 'set';
          }
          break;
        case 'object':
          if (option === 'environment') {
            loggingCopy['environment'] = value;
          } else if (option === 'prefetchUsers') {
            loggingCopy['prefetchUsers'] = ((_c = (_b = options.prefetchUsers) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) > 0;
          } else {
            loggingCopy[String(option)] = value != null ? 'set' : 'unset';
          }
          break;
        case 'function':
          if (option === 'userPersistentStorage') {
            loggingCopy['userPersistentStorage'] = value != null ? 'set' : 'unset';
          }
      }
    });
    this.loggingCopy = loggingCopy;
  };
  StatsigSDKOptions.prototype.getLoggingCopy = function () {
    return this.loggingCopy;
  };
  StatsigSDKOptions.prototype.getOutputLogger = function () {
    return this.logger;
  };
  StatsigSDKOptions.prototype.getApi = function () {
    return this.api;
  };
  StatsigSDKOptions.prototype.getEnvironment = function () {
    return this.environment;
  };
  StatsigSDKOptions.prototype.getDisableCurrentPageLogging = function () {
    return this.disableCurrentPageLogging;
  };
  StatsigSDKOptions.prototype.getLoggingIntervalMillis = function () {
    return this.loggingIntervalMillis;
  };
  StatsigSDKOptions.prototype.getLoggingBufferMaxSize = function () {
    return this.loggingBufferMaxSize;
  };
  StatsigSDKOptions.prototype.getDisableNetworkKeepalive = function () {
    return this.disableNetworkKeepalive;
  };
  StatsigSDKOptions.prototype.getOverrideStableID = function () {
    return this.overrideStableID;
  };
  StatsigSDKOptions.prototype.getLocalModeEnabled = function () {
    return this.localMode;
  };
  StatsigSDKOptions.prototype.getInitTimeoutMs = function () {
    return this.initTimeoutMs;
  };
  StatsigSDKOptions.prototype.getDisableErrorLogging = function () {
    return this.disableErrorLogging;
  };
  StatsigSDKOptions.prototype.getDisableAutoMetricsLogging = function () {
    return this.disableAutoMetricsLogging;
  };
  StatsigSDKOptions.prototype.getInitializeValues = function () {
    return this.initializeValues;
  };
  StatsigSDKOptions.prototype.getEventLoggingApi = function () {
    return this.eventLoggingApi;
  };
  StatsigSDKOptions.prototype.getPrefetchUsers = function () {
    return this.prefetchUsers;
  };
  StatsigSDKOptions.prototype.getDisableLocalStorage = function () {
    return this.disableLocalStorage;
  };
  StatsigSDKOptions.prototype.getInitCompletionCallback = function () {
    return this.initCompletionCallback;
  };
  StatsigSDKOptions.prototype.getUpdateUserCompletionCallback = function () {
    return this.updateCompletionCallback;
  };
  StatsigSDKOptions.prototype.getDisableDiagnosticsLogging = function () {
    return this.disableDiagnosticsLogging;
  };
  StatsigSDKOptions.prototype.getLogLevel = function () {
    return this.logLevel;
  };
  StatsigSDKOptions.prototype.getIgnoreWindowUndefined = function () {
    return this.ignoreWindowUndefined;
  };
  StatsigSDKOptions.prototype.getFetchMode = function () {
    return this.fetchMode;
  };
  StatsigSDKOptions.prototype.getDisableLocalOverrides = function () {
    return this.disableLocalOverrides;
  };
  StatsigSDKOptions.prototype.getGateEvaluationCallback = function () {
    return this.gateEvaluationCallback;
  };
  StatsigSDKOptions.prototype.getUserPersistentStorage = function () {
    return this.userPersistentStorage;
  };
  StatsigSDKOptions.prototype.getDisableHashing = function () {
    return this.disableHashing;
  };
  StatsigSDKOptions.prototype.getInitRequestRetries = function () {
    return this.initRequestRetries;
  };
  StatsigSDKOptions.prototype.isAllLoggingDisabled = function () {
    return this.disableAllLogging;
  };
  StatsigSDKOptions.prototype.reenableAllLogging = function () {
    this.disableAllLogging = false;
  };
  StatsigSDKOptions.prototype.getEvaluationCallback = function () {
    return this.evaluationCallback;
  };
  StatsigSDKOptions.prototype.normalizeNumberInput = function (input, bounds) {
    if (input == null) {
      return bounds.default;
    }
    return Math.max(Math.min(input, bounds.max), bounds.min);
  };
  return StatsigSDKOptions;
}();
exports.default = StatsigSDKOptions;