import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
  standalone: true
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }

    const minutes: number = Math.floor(value / 60);
    const seconds: number = value % 60;

    const minutesString: string = minutes < 10 ? '0' + minutes : minutes.toString();
    const secondsString: string = seconds < 10 ? '0' + seconds : seconds.toString();

    return `${minutesString}:${secondsString}`;
  }
}
