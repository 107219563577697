import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../../../../shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from '../../../../shared/components/button/button.component';

import { IButtonConfig } from '../../../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../../../shared/interfaces/svg.interfaces';

import { EButtonTypes } from '../../../../shared/enums/button.enums';
import { ESvgTypes } from '../../../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-media-audio',
  standalone: true,
  imports: [SvgIconComponent, ButtonComponent, CommonModule],
  templateUrl: './media-audio.component.html',
  styleUrl: './media-audio.component.scss'
})
export class MediaAudioComponent {
  @ViewChild('audioPlayer') audioPlayerRef: ElementRef;
  @Input() media: string;
  audioWaveLength: number[] = Array.from({ length: 28 }, (v, i) => i);
  isAudioPlaying: boolean = false;
  listenButtonConfig: IButtonConfig = {
    fill: EButtonTypes.Colored,
    text: 'Listen'
  };
  playIcon: ISvgConfig = {
    name: 'play_black',
    fill: ESvgTypes.None
  };
  pauseIcon: ISvgConfig = {
    name: 'pause',
    fill: ESvgTypes.None
  };

  toggleAudioPlayPause(): void {
    const audio = document.getElementById('audioPlayer') as HTMLAudioElement;
    if (audio.paused) {
      audio.play();
      this.isAudioPlaying = true;
    } else {
      audio.pause();
      this.isAudioPlaying = false;
    }
  }
}
