import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { CodeVerifyComponent } from '../code-verify/code-verify.component';
import { PaymentFormComponent } from '../payment-form/payment-form.component';

import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../../shared/interfaces/svg.interfaces';

import { EButtonTypes } from '../../../shared/enums/button.enums';
import { ESvgTypes } from '../../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-add-payment-method',
  standalone: true,
  imports: [
    ButtonComponent,
    SvgIconComponent,
    PaymentFormComponent,
    CodeVerifyComponent,
    CommonModule
  ],
  templateUrl: './add-payment-method.component.html',
  styleUrl: './add-payment-method.component.scss'
})
export class AddPaymentMethodComponent {
  @Input() firstCheckOut: boolean;
  @Input() paymentType: string;
  @Input() creatorId: string;
  @Input() wsConnectionId: string;
  @Output() goBackEvent: EventEmitter<void> = new EventEmitter<void>();
  public addMethodBtn: IButtonConfig = { text: 'Use payment method', fill: EButtonTypes.Colored };
  public arrowSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'arrow_left' };

  goBack(): void {
    this.goBackEvent.emit();
  }
}
