import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ISvgConfig } from '../../interfaces/svg.interfaces';
import { ESvgTypes } from '../../enums/svg.enums';

@Component({
  selector: 'stxt-svg-icon',
  standalone: true,
  imports: [CommonModule, HttpClientModule],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss'
})
export class SvgIconComponent implements OnInit {
  @Input() public iconConfig: ISvgConfig;
  @Input() public disabled?: boolean;
  public readonly ESvgTypes = ESvgTypes;
  public svgIcon: SafeHtml | undefined;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getSvgHtml();
  }

  getSvgHtml(): void {
    if (!this.iconConfig.name) return;
    this.httpClient
      .get(`assets/icons/${this.iconConfig.name}.svg`, { responseType: 'text' })
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
      });
  }
}
