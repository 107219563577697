<div *ngIf="creatorData && creatorProfile" class="friendship-panel">
  <div class="friendship-panel_header flex justify-content-between items-center">
    <p>Friendship with {{ creatorData.name }}</p>
    <stxt-svg-icon
      class="close-icon"
      [iconConfig]="closeIcon"
      (click)="closeFriendshipPage.emit(true)"
      (keyup)="closeFriendshipPage.emit(true)"
      tabindex="0"
      role="button"
    ></stxt-svg-icon>
  </div>

  <div class="switch">
    <div
      class="switch_profile"
      [ngClass]="{ selected: selectedTab === EFriendshipTab.Profile }"
      (click)="selectTab(EFriendshipTab.Profile)"
      (keyup)="selectTab(EFriendshipTab.Profile)"
      tabindex="0"
      role="button"
    >
      <div class="switch_profile__header">
        <stxt-svg-icon class="header-icon" [iconConfig]="profileIcon"></stxt-svg-icon>
        <p>{{ creatorData.name }}</p>
      </div>
    </div>
    <div
      class="switch_timeline"
      [ngClass]="{ selected: selectedTab === EFriendshipTab.Timeline }"
      (click)="selectTab(EFriendshipTab.Timeline)"
      (keyup)="selectTab(EFriendshipTab.Timeline)"
      tabindex="0"
      role="tab"
    >
      <div class="switch_timeline__header">
        <stxt-svg-icon class="header-icon" [iconConfig]="timelineIcon"></stxt-svg-icon>
        <p>Timeline</p>
      </div>
    </div>
  </div>

  <div class="friendship-panel_content">
    <div #parallaxContainer class="parallax-container">
      <img
        #parallaxImage
        class="parallax-container_image"
        [src]="creatorData.image"
        alt="creator-thumbnail"
      />
    </div>

    <!-- PROFILE TAB -->
    <ng-container *ngIf="selectedTab === EFriendshipTab.Profile">
      <div class="profile">
        <div class="profile_header">
          <div class="profile_header__name">
            <img src="assets/icons/verified.svg" alt="verified" class="name-badge" />
            <p>{{ creatorProfile.name }}</p>
          </div>
          <p class="profile_header__bio">{{ creatorProfile.bio }}</p>
          <div class="profile_info">
            <div class="profile_info__age">
              <stxt-svg-icon class="creator_svg" [iconConfig]="ageIcon"></stxt-svg-icon>
              <p>{{ creatorProfile.age }} years</p>
            </div>
            <div class="profile_info__height">
              <stxt-svg-icon class="creator_svg" [iconConfig]="heightIcon"></stxt-svg-icon>
              <p>{{ creatorProfile.height }}</p>
            </div>
            <div class="profile_info__ethnicity">
              <stxt-svg-icon class="creator_svg" [iconConfig]="ethnicityIcon"></stxt-svg-icon>
              <p>{{ creatorProfile.nationality }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="profile_gallery">
        <stxt-masonry-gallery
          *ngIf="creatorProfile.content"
          [mediaContent]="creatorProfile.content"
          [userId]="userId"
          [showText]="false"
          [creator]="creatorProfile"
          [postDescription]="'posts'"
        ></stxt-masonry-gallery>
      </div>
    </ng-container>

    <!-- TIMELINE TAB -->
    <ng-container *ngIf="selectedTab === EFriendshipTab.Timeline">
      <div class="timeline">
        <div class="timeline_header">
          <div class="timeline_header__name">
            <img src="assets/icons/verified.svg" alt="verified" class="name-badge" />
            <p>You and {{ creatorData.name }}</p>
          </div>
          <p class="timeline_header__description">
            Friends since {{ friendsSince | date: 'MM/yyyy' }}
          </p>
        </div>
      </div>
      <div class="timeline_gallery">
        <stxt-masonry-gallery
          *ngIf="sharedMedia?.media"
          [mediaContent]="sharedMedia.media"
          [userId]="userId"
          [creator]="creatorProfile"
          [postDescription]="'shared with you'"
        ></stxt-masonry-gallery>
      </div>
    </ng-container>
  </div>
</div>
