import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  HostListener
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { signUp, signIn, signInWithRedirect } from 'aws-amplify/auth';
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';

import { AuthService } from '../../../auth/auth.service';
import { DiscoverService } from '../../services/discover.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';

import { ButtonComponent } from '../button/button.component';
import { InputComponent } from '../input/input.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { RecaptchaTermsComponent } from '../recaptcha-terms/recaptcha-terms.component';

import { IButtonConfig } from '../../interfaces/button.interfaces';
import { IInputCustomConfig } from '../../interfaces/input.interfaces';
import { ISvgConfig } from '../../interfaces/svg.interfaces';
import { ICreatorDiscoverData } from '../../../interfaces/creator.interfaces';

import { EButtonActionTypes, EButtonTypes } from '../../enums/button.enums';
import { EInputCustomType, EInputSupportMessage, EInputUsage } from '../../enums/input.enums';
import { EAuthFormType, ELocalStorageKey } from '../../enums/shared.enums';
import { ESvgTypes } from '../../enums/svg.enums';
import { EAuthType, EUserAuthType } from '../../../auth/auth.enums';
import { ERecaptchaTermsLinks } from 'app/enums/terms-of-service.enums';

@Component({
  selector: 'stxt-auth-form',
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    RecaptchaTermsComponent,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SvgIconComponent,
    CommonModule
  ],
  templateUrl: './auth-form.component.html',
  styleUrl: './auth-form.component.scss',
  providers: [ReCaptchaV3Service]
})
export class AuthFormComponent implements OnInit, OnDestroy {
  @Input() creatorId?: string;
  @Output() closeAuth: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly authFormType: EAuthFormType;
  public authForm: FormGroup;
  public userEmail: string;
  public isSignUp: boolean = true;
  public isSignWithEmail: boolean = false;
  public closeAndNavigate: boolean = false;
  public googleButton: IButtonConfig = {
    fill: EButtonTypes.Empty,
    text: 'Continue with Google',
    middleIcon: 'google'
  };
  public loginButton: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    text: 'Log in'
  };
  public signUpButton: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    text: 'Continue with email'
  };
  public emailInput: IInputCustomConfig = {
    placeholder: 'Email',
    supportingText: {
      text: EInputSupportMessage.Email
    },
    type: EInputCustomType.Email,
    usageCase: EInputUsage.Page
  };

  public passwordInput: IInputCustomConfig = {
    placeholder: 'Password',
    supportingText: {
      text: EInputSupportMessage.Password
    },
    type: EInputCustomType.Password,
    usageCase: EInputUsage.Page,
    hintText: EInputSupportMessage.PasswordHint
  };
  public closeSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'close' };
  public googleSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'google_colorful' };
  public unlockSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'unlock' };
  public mediaSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'media' };
  public heatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'mode_heat' };
  public creator: ICreatorDiscoverData;
  public captchaToken: string;
  public EAuthType = EAuthType;
  public ELocalStorageKey = ELocalStorageKey;
  public ERecaptchaTermsLinks = ERecaptchaTermsLinks;
  isMobile: boolean;
  userNotFoundError: boolean = false;
  userExistsError: boolean = false;
  passwordMatchError: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService,
    public activeModal: NgbActiveModal,
    public discoverService: DiscoverService,
    public analyticsService: AnalyticsService,
    public recaptchaV3Service: ReCaptchaV3Service,
    public navigationIntentService: NavigationIntentService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.initAuthForm();
    this.checkScreenSize();
    if (this.creatorId) {
      this.discoverService
        .getDiscoverCreatorData(this.creatorId)
        .pipe(first())
        .subscribe(res => {
          this.creator = res.creators[0];
        });
    }
    document.body.style.overflow = 'hidden';
  }

  initAuthForm(): void {
    this.authForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          this.userNotFoundValidator,
          this.userExistsValidator
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          this.passwordMatchValidator,
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{8,}$'
          )
        ]
      ]
    });
  }

  userNotFoundValidator: ValidatorFn = () => {
    return !this.isSignUp && this.userNotFoundError ? { userNotFound: true } : null;
  };

  userExistsValidator: ValidatorFn = () => {
    return this.isSignUp && this.userExistsError ? { userExists: true } : null;
  };

  passwordMatchValidator: ValidatorFn = () => {
    return !this.isSignUp && this.passwordMatchError ? { passwordMatch: true } : null;
  };

  executeRecaptcha(): void {
    this.recaptchaV3Service
      .execute('authFormAction')
      .pipe(first())
      .subscribe({
        next: token => {
          this.captchaToken = token;
          this.submitEmail();
        },
        error: error => {
          console.error('reCAPTCHA execution error:', error);
        }
      });
  }

  openTOS(): void {
    window.open('https://stxt.notion.site/Terms-of-Use-62cc644f192f4d7b9dc17d7cb5c9f891', '_blank');
  }

  openPrivacyPolicy(): void {
    window.open('assets/files/Privacy Policy.pdf', '_blank');
  }

  openGTOS(): void {
    window.open(ERecaptchaTermsLinks.TermsOfService, '_blank');
  }

  openGPrivacyPolicy(): void {
    window.open(ERecaptchaTermsLinks.PrivacyPolicy, '_blank');
  }

  submitEmail(): void {
    const userEmail = this.authForm.value.email;
    const password = this.authForm.value.password;

    if (!this.authForm.valid) return;
    this.userEmail = userEmail;
    this.handleCheckAuthType(userEmail, password);
    this.trackAnalytics();
  }

  handleCheckAuthType(userEmail: string, password: string): void {
    if (this.isSignUp) {
      this.handleSignUp(userEmail, password).then(() => {
        this.closeAuth.emit(true);
      });
    } else {
      this.handleSignIn(userEmail, password).then(() => {
        this.closeAuth.emit(true);
      });
    }
  }

  trackAnalytics(): void {
    this.analyticsService.click(
      this.googleButton.text,
      '',
      'Home',
      EButtonActionTypes.TriggerEmailPasswordSignIn,
      ''
    );
  }

  async handleGoogleSignIn() {
    // Store the intended redirect URL before initiating sign-in
    if (this.navigationIntentService.getIntent()) {
      localStorage.setItem(ELocalStorageKey.RedirectUrl, this.navigationIntentService.getIntent());
    }

    try {
      await signInWithRedirect({
        provider: 'Google'
      });

      // Note: The code after signInWithRedirect won't execute immediately
      // as the user will be redirected to Google's login page
      this.setUser(EUserAuthType.Google_Sign_In);

      this.analyticsService.click(
        this.googleButton.text,
        '',
        'Home',
        EButtonActionTypes.TriggerGoogleSignIn,
        ''
      );
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  }

  async handleSignUp(userEmail: string, password: string): Promise<void> {
    try {
      await signUp({ username: userEmail, password: password });
      await signIn({ username: userEmail, password: password });
      await this.authService.currentSession();
      this.closeAuth.emit(true);
      this.activeModal.close();
      this.authService.identifyUser('signUp', userEmail);
    } catch (error) {
      if (error instanceof Error && error?.message.includes('User already exists')) {
        this.setEmailSupportingText(EInputSupportMessage.EmailExists, 'log in.');
        this.handleEmailValidation('exists');
      }
    }
  }

  async handleSignIn(userEmail: string, password: string) {
    try {
      await signIn({
        username: userEmail,
        password: password
      }).then(() => this.setUser(EUserAuthType.Email_Password, userEmail));
      await this.authService.currentSession();
      this.closeAuth.emit(true);
      this.activeModal.close();
    } catch (error) {
      if (error instanceof Error && error.message.includes('User does not exist')) {
        this.setEmailSupportingText(EInputSupportMessage.EmailNotFound, 'sign up.');
        this.handleEmailValidation('notFound');
      } else {
        this.setPasswordSupportingText(EInputSupportMessage.PasswordMatch);
        this.handlePasswordValidation();
      }
    }
  }

  private setEmailSupportingText(text: EInputSupportMessage, buttonText?: string): void {
    const emailControl = this.authForm.get('email');
    this.emailInput.supportingText = { text, buttonText };
    this.emailInput.supportingText.buttonAction = () => {
      emailControl.removeValidators(this.userExistsValidator);
      emailControl.updateValueAndValidity();
      this.isSignUp = buttonText === 'sign up.';
    };
  }

  private setPasswordSupportingText(text: EInputSupportMessage): void {
    this.passwordInput.supportingText = { text };
  }

  private handleEmailValidation(validationType: 'exists' | 'notFound'): void {
    const emailControl = this.authForm.get('email');
    if (!emailControl) return;

    const isUserExists = validationType === 'exists';
    const errorFlag = isUserExists ? 'userExistsError' : 'userNotFoundError';
    const validator = isUserExists ? this.userExistsValidator : this.userNotFoundValidator;

    this.applyValidator(emailControl, errorFlag, validator, () => {
      this.isSignUp = !isUserExists;
      this.setEmailSupportingText(EInputSupportMessage.Email, isUserExists ? null : undefined);
    });
  }

  private handlePasswordValidation(): void {
    const passwordControl = this.authForm.get('password');
    if (!passwordControl) return;

    this.applyValidator(passwordControl, 'passwordMatchError', this.passwordMatchValidator, () => {
      this.setPasswordSupportingText(EInputSupportMessage.Password);
    });
  }

  private applyValidator(
    control: AbstractControl,
    errorFlag: 'userNotFoundError' | 'userExistsError' | 'passwordMatchError',
    validator: ValidatorFn,
    onReset: () => void
  ): void {
    this[errorFlag] = true;
    control.addValidators(validator);
    control.updateValueAndValidity({ emitEvent: true });

    control.valueChanges.pipe(first()).subscribe(() => {
      this[errorFlag] = false;
      control.removeValidators(validator);
      control.updateValueAndValidity();
      onReset();
    });
  }

  setUser(type: string, userEmail?: string): void {
    const user = {
      username: userEmail || '',
      password: '',
      type: type
    };
    localStorage.setItem('user', JSON.stringify(user));
  }

  handleCaptcha(token: string) {
    this.captchaToken = token;
  }

  closeModal(): void {
    this.activeModal.close();
    if (!this.closeAndNavigate) return;
    this.router.navigate(['/']);
    localStorage.removeItem(ELocalStorageKey.RedirectUrl);
    this.navigationIntentService.clearIntent();
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 640;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    document.body.style.removeProperty('overflow');
  }
}
