export enum EUserCredential {
  Password = 'password',
  Email = 'email address'
}

export enum EProfileSectionTitle {
  My_Profile = 'My profile',
  View_Subscription_Info = 'View subscription info',
  View_Billing_History = 'View billing history',
  View_Transaction_Info = 'View transaction info',
  Verify_Email = 'Verify email'
}
