<div class="audio-wrapper">
  <p class="creator_starter-block__head">Chat starter topic</p>
  <div class="creator_starter-block__body">
    <div class="loader">
      <span
        *ngFor="let index of audioWaveLength"
        [ngClass]="{ animate: isAudioPlaying === true }"
        class="stroke"
      ></span>
    </div>
  </div>
  <div class="creator_starter-block__button">
    <audio #audioPlayer id="audioPlayer">
      <source src="{{ media }}" type="audio/mp3" />
      Your browser does not support the audio tag.
    </audio>
    <stxt-button (click)="toggleAudioPlayPause()" [buttonConfig]="listenButtonConfig">
      <div class="button-content flex items-center">
        <ng-container *ngIf="!isAudioPlaying">
          <stxt-svg-icon class="chat_svg" [iconConfig]="playIcon"></stxt-svg-icon>
          <p>{{ listenButtonConfig.text }}</p>
        </ng-container>
        <ng-container class="flex" *ngIf="isAudioPlaying">
          <stxt-svg-icon class="chat_svg" [iconConfig]="pauseIcon"></stxt-svg-icon>
          <p>Pause</p>
        </ng-container>
      </div>
    </stxt-button>
  </div>
</div>
