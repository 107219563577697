import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { first } from 'rxjs';

import { ConversationService } from '../../shared/services/conversation.service';

import { ChatItemComponent } from '../../components/chat-item/chat-item.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';

import {
  IConversation,
  IConversationsListResponse
} from '../../interfaces/conversations.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';

import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-conversations-page',
  standalone: true,
  imports: [CommonModule, ChatItemComponent, ButtonComponent],
  templateUrl: './conversations-page.component.html',
  styleUrl: './conversations-page.component.scss'
})
export class ConversationsPageComponent implements OnInit {
  conversationsList: IConversation[];
  discoverButtonConfig: IButtonConfig = {
    text: 'Discover',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  conversationsLoaded = false;
  constructor(
    public conversationService: ConversationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getConversationsList();
  }

  getConversationsList(): void {
    this.conversationService
      .getConversationsList()
      .pipe(first())
      .subscribe((res: IConversationsListResponse) => {
        this.conversationsList = res.chats;
        this.conversationsLoaded = true;
      });
  }

  discover(): void {
    this.router.navigate(['']);
  }
}
