import { Component }    from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-footer',
  standalone: true,
  imports: [ CommonModule, SvgIconComponent ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public arrowSvg: ISvgConfig = { name: 'arrow-ne', fill: ESvgTypes.None };
  public footerLinks: { title: string; url: string; }[] = [
    {
      title: 'Terms & Conditions',
      url: 'https://stxt.notion.site/Terms-of-Use-62cc644f192f4d7b9dc17d7cb5c9f891'
    },
    {
      title: 'Privacy Policy',
      url: 'assets/files/Privacy Policy.pdf'
    },
    {
      title: 'Content Removal Policy',
      url: 'https://stxt.notion.site/Content-Removal-Policy-68ca0e31926e42f8a01f63f1cd245094'
    },
    {
      title: 'Complaints Policy',
      url: 'https://stxt.notion.site/Complaints-Policy-f0d5a250e2054f4ca360e94bac055321'
    },
    {
      title: 'Help Center',
      url: 'https://airtable.com/appxBO8pYpQMldSMV/pagoUYCJBBdTcDJgm/form'
    },
  ];
}
