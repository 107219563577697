export enum ESubscriptionState {
  Active = 'active',
  Soon = 'soon',
  Expired = 'expired'
}

export enum ESubscriptionManagerPart {
  Billing_Info = 'billing_info',
  Billing_History = 'billing_history',
  Transaction_Info = 'transaction_info',
}
