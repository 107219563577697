import { Injectable }              from '@angular/core';
import { HttpClient }              from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  ICreatorSubscriptionBody, IMediaPurchaseBody,
  IPaymentsResponse
} from 'app/interfaces/payments.interfaces';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private apiUrl = environment.apiBaseUrl;
  $submitPayment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  creatorSubscriptionRequest(requestBody: ICreatorSubscriptionBody): Observable<IPaymentsResponse> {
    const endpoint = this.apiUrl + `/chats/${ requestBody.creator_id }/subscribe`;
    return this.http.post<IPaymentsResponse>(endpoint, requestBody);
  }

  purchaseMediaRequest(requestBody: IMediaPurchaseBody): Observable<IPaymentsResponse> {
    const endpoint = this.apiUrl + `/chats/${ requestBody.creator_id }/purchase`;
    return this.http.post<IPaymentsResponse>(endpoint, requestBody);
  }
}
