import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationTriggerMetadata
} from '@angular/animations';

export const slideInOut: AnimationTriggerMetadata = trigger('slideInOut', [
  state('closed', style({ right: '-100%' })),
  state('open', style({ right: '0' })),
  transition('closed => open', animate('300ms ease-in-out')),
  transition('open => closed', animate('150ms ease-out'))
]);
