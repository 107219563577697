<div *ngIf="media" class="message-media" [ngClass]="{ mediaLoaded: isMediaLoaded }">
  <p class="message-media_head">
    <ng-container *ngIf="media.photos > 1">{{ media.photos }} IMAGES</ng-container>
    <ng-container *ngIf="media.photos === 1">
      <div class="flex items-center">
        <stxt-svg-icon class="message-media_svg" [iconConfig]="imageSvg"></stxt-svg-icon>
        <span>IMAGE</span>
      </div>
    </ng-container>
    <span>
      <ng-container *ngIf="media.photos > 0 && media.videos > 0">•</ng-container>
      <ng-container *ngIf="media.videos > 0">{{ media.videos }} VIDEOS</ng-container>
    </span>
  </p>
  <div class="message-media_wrapper">
    <ngb-carousel
      #smallCarousel
      [showNavigationArrows]="false"
      [showNavigationIndicators]="media.photos > 1"
      (slide)="onSlide($event)"
    >
      <ng-container *ngFor="let file of media.files; index as i">
        <ng-template id="ngb-slide-{{ i }}" ngbSlide>
          <div class="picsum-img-wrapper">
            <div class="image-container">
              <div
                class="blurred-background"
                [style.background-image]="
                  file.owned ? 'url(' + file.url + ')' : 'url(assets/d2c-images/thumbnail.png)'
                "
              ></div>
              <img
                class="carousel_image relative"
                [ngClass]="{ blur: !file.owned && media.price > 0 }"
                [src]="file.owned ? file.url : 'assets/d2c-images/thumbnail.png'"
                (load)="onImageLoad()"
                (click)="togglePreview(true)"
                (keydown.enter)="togglePreview(true)"
                tabindex="0"
                alt=""
              />
            </div>
            <ng-container *ngIf="!file.owned && media.price > 0">
              <stxt-svg-icon
                class="lock_svg"
                [iconConfig]="lockSvg"
                (click)="togglePreview(true)"
              ></stxt-svg-icon>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>
  </div>
  <stxt-button
    *ngIf="mediaOwned || media.price === 0"
    (click)="unlockImage()"
    class="unlock_btn"
    [buttonConfig]="viewBtn"
  >
    {{ viewBtn.text }}
  </stxt-button>
  <stxt-button
    *ngIf="!mediaOwned && media.price > 0"
    (click)="unlockImage()"
    class="unlock_btn"
    [buttonConfig]="unlockBtn"
  >
    <div class="button-content">
      <stxt-svg-icon class="unlock-media_svg" [iconConfig]="unlockMediaSvg"></stxt-svg-icon>
      <p>{{ unlockBtn.text }}</p>
    </div>
  </stxt-button>
</div>

<ng-container *ngIf="mediaFullScreen">
  <div class="media_overlay">
    <div class="media_preview__head flex">
      <div class="flex">
        <stxt-svg-icon
          class="checkout_icon"
          [iconConfig]="closeSvg"
          (click)="togglePreview(false)"
        ></stxt-svg-icon>
      </div>
      <stxt-button
        *ngIf="mediaOwned === false && media.price > 0"
        class="unlock_preview__btn"
        [buttonConfig]="unlockBtn"
        (click)="unlockImage()"
      >
        {{ unlockBtn.text }}
      </stxt-button>
    </div>
    <div class="media_preview__carousel">
      <ngb-carousel
        #carousel
        [showNavigationArrows]="false"
        [showNavigationIndicators]="media.photos > 1"
      >
        <ng-container *ngFor="let file of media.files; index as i">
          <ng-template id="ngb-slide-{{ i }}" ngbSlide>
            <div
              [ngClass]="{ 'owned-media': file.owned, 'locked-media': !file.owned }"
              [style.background-image]="
                'url(' + (file.owned ? file.url : 'assets/d2c-images/stxt_thumbnail.png') + ')'
              "
              class="picsum-img-wrapper media_preview__body relative"
            >
              <div class="media_preview__image-wrapper">
                <img
                  [src]="file.owned ? file.url : 'assets/d2c-images/stxt_thumbnail.png'"
                  class="carousel_image preview_image relative"
                  alt="{{ i }}"
                />
              </div>
            </div>
            <div *ngIf="media.photos > 1" class="media_preview__navigation absolute">
              <div
                [ngClass]="{ 'hidden-nav': i === 0 }"
                (click)="carousel.prev()"
                (keydown.arrowLeft)="carousel.prev()"
                tabindex="0"
              >
                <stxt-svg-icon [iconConfig]="leftNavigation"></stxt-svg-icon>
              </div>
              <div
                [ngClass]="{ 'hidden-nav': i === media.photos - 1 }"
                (click)="carousel.next()"
                (keydown.arrowRight)="carousel.next()"
                tabindex="0"
              >
                <stxt-svg-icon [iconConfig]="rightNavigation"></stxt-svg-icon>
              </div>
            </div>
            <div *ngIf="media.photos > 1" class="media_preview__slides">
              {{ i + 1 }} of {{ media.photos }} photos
            </div>
          </ng-template>
        </ng-container>
      </ngb-carousel>
    </div>
  </div>
</ng-container>
