import { Component, Input } from '@angular/core';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-loading',
  standalone: true,
  imports: [ SvgIconComponent ],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  @Input() public loadingText: string;
  public logoSvg: ISvgConfig = { name: 'stxt-logo', fill: ESvgTypes.None };
}
