"use strict";

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable @typescript-eslint/no-explicit-any */
var StatsigSDKOptions_1 = require("../StatsigSDKOptions");
var _logger = console;
var _logLevel = StatsigSDKOptions_1.LogLevel.WARN;
var OutputLogger = /** @class */function () {
  function OutputLogger() {}
  OutputLogger.getLogger = function () {
    return _logger;
  };
  OutputLogger.debug = function (message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }
    if (_logLevel !== StatsigSDKOptions_1.LogLevel.NONE) {
      _logger.debug && _logger.debug.apply(_logger, __spreadArray(["".concat(this.LOG_PREFIX, " ").concat(message)], optionalParams, false));
    }
  };
  OutputLogger.info = function (message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }
    if (_logLevel === StatsigSDKOptions_1.LogLevel.INFO) {
      _logger.info.apply(_logger, __spreadArray(["".concat(this.LOG_PREFIX, " ").concat(message)], optionalParams, false));
    }
  };
  OutputLogger.warn = function (message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }
    if (_logLevel === StatsigSDKOptions_1.LogLevel.WARN) {
      _logger.warn.apply(_logger, __spreadArray(["".concat(this.LOG_PREFIX, " ").concat(message)], optionalParams, false));
    }
  };
  OutputLogger.error = function (message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      optionalParams[_i - 1] = arguments[_i];
    }
    if (_logLevel === StatsigSDKOptions_1.LogLevel.ERROR) {
      _logger.error.apply(_logger, __spreadArray(["".concat(this.LOG_PREFIX, " ").concat(message)], optionalParams, false));
    }
  };
  OutputLogger.setLogger = function (logger) {
    _logger = logger;
  };
  OutputLogger.setLogLevel = function (logLevel) {
    _logLevel = logLevel;
  };
  OutputLogger.resetLogger = function () {
    _logger = console;
    _logLevel = StatsigSDKOptions_1.LogLevel.WARN;
  };
  OutputLogger.LOG_PREFIX = '[Statsig]';
  return OutputLogger;
}();
exports.default = OutputLogger;