export enum EAuthFormType {
  Auth = 'Auth',
  Lander = 'Lander'
}

export enum EResendCodeUsage {
  Profile = 'profile',
  Chat = 'chat'
}

export enum ELocalStorageKey {
  VerifySent = 'verifySent',
  CognitoAccessToken = 'cognitoAccessToken',
  CountdownVerificationStartTime = 'countdownVerificationStartTime',
  RedirectUrl = 'redirectUrl'
}

export enum ECarouselNavigation {
  Previous = 'previous',
  Next = 'next'
}
