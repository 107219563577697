import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCount',
  standalone: true
})
export class NumberCountPipe implements PipeTransform {
  transform(num: number, digits: number = 1): string {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' }
    ];
    const regexp = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.reverse().find(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
  }
}
