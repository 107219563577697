<ng-container *ngIf="!isChat">
  <stxt-header
    *ngIf="!isLander"
    [ngStyle]="{ display: showHeader === true ? 'flex' : 'none' }"
  ></stxt-header>
</ng-container>
<div class="app_content flex items-center justify-center">
  <router-outlet></router-outlet>
  <ng-container *ngIf="isLander">
    <stxt-auth-form (closeAuth)="isLander = false" formType="Lander"></stxt-auth-form>
  </ng-container>
</div>
<ng-container *ngIf="!isChat">
  <stxt-footer></stxt-footer>
</ng-container>
