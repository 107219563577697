import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Observable, first } from 'rxjs';

import { EmailService } from '../../../shared/services/email.service';
import { AuthService } from 'app/auth/auth.service';

import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { InputComponent } from '../../../shared/components/input/input.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ResendCodeComponent } from '../../../shared/components/resend-code/resend-code.component';
import { SuccessToastComponent } from '../../../shared/components/success-toast/success-toast.component';

import { IUserAttributeVerify } from 'app/auth/auth.interfaces';
import { IInputCustomConfig } from '../../../shared/interfaces/input.interfaces';
import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { ISvgConfig } from '../../../shared/interfaces/svg.interfaces';

import {
  EInputCustomType,
  EInputSupportMessage,
  EInputUsage
} from '../../../shared/enums/input.enums';
import { EButtonSizes, EButtonTypes } from '../../../shared/enums/button.enums';
import { ESvgTypes } from '../../../shared/enums/svg.enums';
import { EVerifyAttributeName } from 'app/auth/auth.enums';
import { EResendCodeUsage } from '../../../shared/enums/shared.enums';

@Component({
  selector: 'stxt-code-verify',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    CommonModule,
    SvgIconComponent,
    ResendCodeComponent,
    SuccessToastComponent
  ],
  templateUrl: './code-verify.component.html',
  styleUrl: './code-verify.component.scss'
})
export class CodeVerifyComponent implements OnInit {
  @Input() paymentType: string;
  @Input() isVerifyingState: boolean = false;
  @Input() verifiedState: boolean = false;
  @Input() email: string;
  @Output() userVerified: EventEmitter<void> = new EventEmitter<void>();
  verifyForm: FormGroup;
  verifyButton: IButtonConfig = {
    text: 'Submit',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  verifyInput: IInputCustomConfig = {
    placeholder: '6-digit code',
    supportingText: {
      text: EInputSupportMessage.VerifyCodeRequired
    },
    type: EInputCustomType.Number,
    usageCase: EInputUsage.Page
  };
  checkVerifyIcon: ISvgConfig = {
    name: 'verify',
    fill: ESvgTypes.None
  };
  inputCodeLength: number = 6;
  authType: string;
  token = localStorage.getItem('accessToken');
  cognitoAccessToken = localStorage.getItem('cognitoAccessToken');
  resendCodeCountdown = {
    countdown$: new Observable<number>(),
    countdownDuration: 60,
    countdownKey: 'countdown_verification_start_time',
    isCountdownStarted: false
  };
  protected readonly EResendCodeUsage = EResendCodeUsage;
  isToastMessage: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public emailService: EmailService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initVerifyForm();
  }

  initVerifyForm(): void {
    this.verifyForm = this.formBuilder.group({
      code: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]/),
          Validators.minLength(this.inputCodeLength),
          Validators.maxLength(this.inputCodeLength)
        ]
      ]
    });
  }

  submitVerify(): void {
    const verifyCode = this.verifyForm.get('code');
    const verifyData: IUserAttributeVerify = {
      attribute_name: EVerifyAttributeName.EMAIL,
      cognito_access_token: this.cognitoAccessToken,
      verification_code: verifyCode.value
    };
    if (this.verifyForm?.valid) {
      this.authService
        .verifyCode(verifyData)
        .pipe(first())
        .subscribe({
          next: () => {
            this.successVerifyFlow();
          },
          error: err => {
            if (err.status === 200) {
              this.successVerifyFlow();
            }
            console.error(err);
            this.verifiedState = false;
            throw new Error(err.error.message);
          }
        });
    }
  }

  successVerifyFlow(): void {
    this.verifiedState = true;
    this.isVerifyingState = false;
    this.userVerified.emit();
    localStorage.removeItem(this.resendCodeCountdown.countdownKey);
  }
}
