<div class="subscription-modal" ngbAutofocus>
  <img class="subscription-modal_image" [src]="creatorData.thumbnail_image" [alt]="creatorData.creator_name"/>
  <stxt-svg-icon class="subscription-modal_svg" [iconConfig]="closeSvg" (click)="activeModal.close()"></stxt-svg-icon>
  <div class="subscription-modal_content">
    <p class="subscription-modal_heading">Keep chatting with me</p>
    <p class="subscription-modal_subheading">
      Subscribe to {{ creatorData.creator_name }} for just {{ subscriptionPrice / 100 | currency }}/month.
    </p>
    <div class="subscription-modal_benefits flex">
      <div class="subscription-modal_benefit flex">
        <stxt-svg-icon class="subscription-modal_check-svg" [iconConfig]="checkSvg"></stxt-svg-icon>
        <p>Unlimited chat <span>any time, anywhere</span></p>
      </div>
      <div class="subscription-modal_benefit flex">
        <stxt-svg-icon class="subscription-modal_check-svg" [iconConfig]="checkSvg"></stxt-svg-icon>
        <p>Custom requests <span>fast and on demand</span></p>
      </div>
      <div class="subscription-modal_benefit flex">
        <stxt-svg-icon class="subscription-modal_check-svg" [iconConfig]="checkSvg"></stxt-svg-icon>
        <p>VIP {{ creatorData.creator_name }} content <span>just for you</span></p>
      </div>
    </div>
    <stxt-button (click)="openPaymentEvent()" class="subscription-modal_btn" [buttonConfig]="subscribeBtn">
      {{ subscribeBtn.text }}
    </stxt-button>
    <p class="subscription-modal_footer-text">Risk free, cancel any time.</p>
  </div>
</div>
