import { Component, Input } from '@angular/core';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-canned-message',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './canned-message.component.html',
  styleUrl: './canned-message.component.scss'
})
export class CannedMessageComponent {
  @Input() message: string;
  sendIcon: ISvgConfig = {
    name: 'interactive', fill: ESvgTypes.None
  }
}
