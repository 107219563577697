import { Routes } from '@angular/router';

import { authGuard } from './auth/auth.guard';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { ConversationPageComponent } from './pages/conversation-page/conversation-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { CreatorProfilePageComponent } from './pages/creator-profile-page/creator-profile-page.component';
import { ConversationsPageComponent } from './pages/conversations-page/conversations-page.component';

export const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'lander', component: HomePageComponent },
  { path: 'chats', component: ConversationsPageComponent, canActivate: [authGuard] },
  { path: 'chat/:chatId', component: ConversationPageComponent, canActivate: [authGuard] },
  { path: 'profile', component: ProfilePageComponent },
  { path: 'creator/:creatorId', component: CreatorProfilePageComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
