import { Component, EventEmitter, Output } from '@angular/core';
import { Router }                          from '@angular/router';

import { signOut } from 'aws-amplify/auth';

import { AuthService } from '../../../auth/auth.service';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-support',
  standalone: true,
  imports: [ SvgIconComponent ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss'
})
export class SupportComponent {
  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>();
  public arrowSvgConfig: ISvgConfig = { name: 'arrow-ne-outlined', fill: ESvgTypes.None };
  public tosLink: string = 'https://stxt.notion.site/Terms-of-Use-62cc644f192f4d7b9dc17d7cb5c9f891';
  public helpCenterLink: string = 'https://airtable.com/appxBO8pYpQMldSMV/pagoUYCJBBdTcDJgm/form';
  protected readonly window = window;
  private localStorageKeys: string[] = [ 'accessToken', 'cognitoAccessToken', 'creatorInfo', 'backUrl' ];

  constructor(
    private authService: AuthService, 
    private router: Router
  ) {
  }

  async logOut(): Promise<void> {
    try {
      await signOut().then(() => {
        this.localStorageKeys.forEach(key => localStorage.removeItem(key));
        this.authService.currentSession();
        this.authService.userLoaded$.next(false);
        this.logoutEvent.emit();
        this.router.navigate(['/']);
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
