"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function Polyfill() {
  if (!Object.entries) {
    // Pollyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
    Object.entries = function (obj) {
      var ownProps = Object.keys(obj);
      var i = ownProps.length;
      var resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
      return resArray;
    };
  }
}
exports.default = Polyfill;