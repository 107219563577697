<div class="support-section w-full">
  <h4 class="support-section_heading">Support</h4>
  <div class="support-section_item w-full flex space-between items-center">
    <p class="support-section_item__name">Terms of use</p>
    <stxt-svg-icon
      class="support-section_arrow-svg"
      [iconConfig]="arrowSvgConfig"
      (click)="window.open(tosLink, '_blank')"
    ></stxt-svg-icon>
  </div>
  <div class="support-section_item w-full flex space-between items-center">
    <p class="support-section_item__name">Help center</p>
    <stxt-svg-icon
      class="support-section_arrow-svg"
      [iconConfig]="arrowSvgConfig"
      (click)="window.open(helpCenterLink, '_blank')"
    ></stxt-svg-icon>
  </div>
  <div class="support-section_item w-full flex items-center">
    <p
      class="support-section_item__name pointer"
      (click)="logOut()"
      (keydown.enter)="logOut()"
      tabindex="0"
    >
      Log out
    </p>
  </div>
</div>
