import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { TransactionInfoComponent } from '../transaction-info/transaction-info.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IBillingHistory } from 'app/interfaces/profile.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EProfileSectionTitle } from 'app/enums/profile.enums';

@Component({
  selector: 'stxt-billing-history',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, TransactionInfoComponent],
  templateUrl: './billing-history.component.html',
  styleUrl: './billing-history.component.scss'
})
export class BillingHistoryComponent {
  @Input() billingHistoryItems: IBillingHistory[];
  @Input() creator: string;
  public rightNavSvg: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };
  public selectedTransaction: IBillingHistory;
  public profileSettingsTitle$: BehaviorSubject<string> = this.profileService.profileActiveSection$;
  protected readonly EProfileSectionTitle = EProfileSectionTitle;

  constructor(public profileService: ProfileService) {}

  openTransactionInfo(billingItem: IBillingHistory): void {
    this.selectedTransaction = billingItem;
    this.profileService.profileActiveSection$.next(EProfileSectionTitle.View_Transaction_Info);
  }
}
