import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { updatePassword, UpdatePasswordInput } from 'aws-amplify/auth';

import {
  IBillingData,
  IBillingHistory,
  IBillingInfoData,
  IBillingPostResponse,
  ISubscriptionAutoRenew,
  ITransactionItem,
  IUserProfile,
  IUserSubscription
} from 'app/interfaces/profile.interfaces';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private apiUrl = `${environment.apiBaseUrl}`;
  public profileActiveSection$: BehaviorSubject<string> = new BehaviorSubject<string>('My profile');

  constructor(private http: HttpClient) {}

  getProfileData(): Observable<IUserProfile> {
    const endpoint = this.apiUrl + `/users/profile`;
    return this.http.get<IUserProfile>(endpoint);
  }

  /*
    The method below is for saving the data from different parts of profile
    without repeating same service for each component, instead we pass the datatype
    as a parameter at T.
  */
  updateProfileData<T>(profileData: T): Observable<T> {
    const endpoint = this.apiUrl + `/users/profile`;
    return this.http.put<T>(endpoint, profileData);
  }

  async handleUpdatePassword({ oldPassword, newPassword }: UpdatePasswordInput): Promise<void> {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (err) {
      console.log(err);
    }
  }

  getBillingInfo(): Observable<IBillingInfoData> {
    const endpoint = this.apiUrl + `/users/billing`;
    return this.http.get<IBillingInfoData>(endpoint);
  }

  addBillingInfo(requestBody: IBillingData): Observable<IBillingInfoData> {
    const endpoint = this.apiUrl + `/users/billing`;
    return this.http.post<IBillingInfoData>(endpoint, requestBody);
  }

  generateSecureToken(): Observable<IBillingPostResponse> {
    const endpoint = this.apiUrl + `/users/billing`;
    return this.http.post<IBillingPostResponse>(endpoint, {});
  }

  removeBillingInfo(id: string): Observable<void> {
    const endpoint = this.apiUrl + `/users/billing`;
    const payload = { payment_method_id: id };
    return this.http.delete<void>(endpoint, { body: payload });
  }

  getUserSubscriptions(): Observable<IUserSubscription[]> {
    const endpoint = this.apiUrl + '/users/subscriptions';
    return this.http.get<IUserSubscription[]>(endpoint);
  }

  updateSubscription(payload: ISubscriptionAutoRenew): Observable<ISubscriptionAutoRenew> {
    const endpoint = this.apiUrl + '/users/subscriptions';
    return this.http.put<ISubscriptionAutoRenew>(endpoint, payload);
  }

  getAllUserTransactions(): Observable<IBillingHistory[]> {
    const endpoint = this.apiUrl + '/users/transactions';
    return this.http.get<IBillingHistory[]>(endpoint);
  }

  getUserTransactionsByCreatorId(creatorId: string): Observable<IBillingHistory[]> {
    const endpoint = this.apiUrl + `/users/transactions?creator_id=${creatorId}`;
    return this.http.get<IBillingHistory[]>(endpoint);
  }

  getUserTransactionItem(transactionId: string): Observable<ITransactionItem> {
    const endpoint = this.apiUrl + `/users/transactions/${transactionId}`;
    return this.http.get<ITransactionItem>(endpoint);
  }
}
