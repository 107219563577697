import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from 'app/auth/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken: string | null = this.authService.getAuthToken();
    const cognitoAccessToken: string | null = this.authService.getCognitoAccessToken();

    if (authToken) {
      const authReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`)
      });
      return next.handle(authReq);
    } else if (cognitoAccessToken) {
      const authReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${cognitoAccessToken}`)
      });
      return next.handle(authReq);
    }

    return next.handle(request);
  }
}