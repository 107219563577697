import { Component, Input } from '@angular/core';
import { NgbActiveModal }   from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent }  from '../../../shared/components/button/button.component';
import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { ISvgConfig }    from '../../../shared/interfaces/svg.interfaces';

import { EButtonTypes } from '../../../shared/enums/button.enums';
import { ESvgTypes }    from '../../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-payment-succeed',
  standalone: true,
  imports: [ ButtonComponent, SvgIconComponent ],
  templateUrl: './payment-succeed.component.html',
  styleUrl: './payment-succeed.component.scss'
})
export class PaymentSucceedComponent {
  @Input() paymentType: string;
  public continueBtn: IButtonConfig = {
    text: 'Continue', fill: EButtonTypes.Colored
  };
  public closeSvg: ISvgConfig = { fill: ESvgTypes.Outlined, name: 'close' };

  constructor(public activeModal: NgbActiveModal) {
  }

  close(): void {
    this.activeModal.close();
  }

}
