<div
  [ngClass]="{
    'page-input': inputConfig?.usageCase === EInputUsage.Page,
    'modal-input': inputConfig?.usageCase === EInputUsage.Modal
  }"
  class="input-custom mb-3"
>
  <div class="input-wrapper">
    <input
      [formControl]="formControl"
      [class.invalid]="isError"
      [min]="inputConfig.value"
      [placeholder]="inputConfig?.placeholder"
      [type]="hide ? inputConfig?.type : EInputCustomType.Text"
      [value]="value"
      (focusout)="focusOut(formControl)"
      (input)="change()"
      class="form-control"
    />
    <ng-container *ngIf="inputConfig.type === 'password'">
      <ng-container *ngIf="!hide">
        <stxt-svg-icon
          [iconConfig]="passwordVisibleIcon"
          (click)="changeType(true)"
          class="password-eye"
        ></stxt-svg-icon>
      </ng-container>

      <ng-container *ngIf="hide">
        <stxt-svg-icon
          [class.invalid-svg]="isError"
          [iconConfig]="passwordInvisibleIcon"
          (click)="changeType(false)"
          class="password-eye"
        ></stxt-svg-icon>
      </ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="isError; else hint"
    [class.invalid-message]="isError"
    class="input-custom-error-message supporting-text"
  >
    <stxt-svg-icon [iconConfig]="errorIcon" class="error_icon"></stxt-svg-icon>
    <p>
      {{ inputConfig.supportingText.text }}
      <button
        *ngIf="inputConfig.supportingText?.buttonText"
        (click)="inputConfig.supportingText?.buttonAction()"
      >
        {{ inputConfig.supportingText.buttonText }}
      </button>
    </p>
  </div>
  <ng-template #hint>
    <div *ngIf="hintText" class="supporting-text">
      <p>{{ hintText }}</p>
    </div>
  </ng-template>
</div>
