import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { first } from 'rxjs';

import { AuthService } from '../../../auth/auth.service';
import { EmailService } from '../../../shared/services/email.service';

import { ButtonComponent } from '../../../shared/components/button/button.component';
import { InputComponent } from '../../../shared/components/input/input.component';
import { ResendCodeComponent } from '../../../shared/components/resend-code/resend-code.component';
import { SuccessToastComponent } from '../../../shared/components/success-toast/success-toast.component';

import { IInputCustomConfig } from '../../../shared/interfaces/input.interfaces';
import { IButtonConfig } from '../../../shared/interfaces/button.interfaces';
import { IUserAttributeVerify } from '../../../auth/auth.interfaces';

import {
  EInputCustomType,
  EInputSupportMessage,
  EInputUsage
} from '../../../shared/enums/input.enums';
import { EButtonSizes, EButtonTypes } from '../../../shared/enums/button.enums';
import { ELocalStorageKey, EResendCodeUsage } from '../../../shared/enums/shared.enums';
import { EVerifyAttributeName } from '../../../auth/auth.enums';

@Component({
  selector: 'stxt-verify-email',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    ResendCodeComponent,
    SuccessToastComponent
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent implements OnInit {
  @Input() email: string;
  @Output() emailVerified: EventEmitter<void> = new EventEmitter<void>();
  inputCodeLength: number = 6;
  verifyForm: FormGroup;
  verifyCodeInput: IInputCustomConfig = {
    placeholder: '6-digit code',
    supportingText: {
      text: EInputSupportMessage.VerifyCodeRequired
    },
    type: EInputCustomType.Number,
    usageCase: EInputUsage.Page
  };
  verifyButton: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    text: 'Verify',
    buttonSize: EButtonSizes.Default
  };
  isToastMessage: boolean = false;
  cognitoAccessToken = localStorage.getItem(ELocalStorageKey.CognitoAccessToken);
  protected readonly EResendCodeUsage = EResendCodeUsage;

  constructor(
    public authService: AuthService,
    public emailService: EmailService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initVerifyForm();
    this.email = this.emailService.modifyEmail(this.email);
  }

  initVerifyForm(): void {
    this.verifyForm = this.formBuilder.group({
      verifyCode: [
        Validators.required,
        Validators.pattern(/^[0-9]/),
        Validators.minLength(this.inputCodeLength),
        Validators.maxLength(this.inputCodeLength)
      ]
    });
  }

  verifyEmail(): void {
    if (!this.verifyForm.valid) return;
    const verifyCode = this.verifyForm.get('code');
    const verifyData: IUserAttributeVerify = {
      attribute_name: EVerifyAttributeName.EMAIL,
      cognito_access_token: this.cognitoAccessToken,
      verification_code: verifyCode.value
    };
    this.authService
      .verifyCode(verifyData)
      .pipe(first())
      .subscribe({
        next: () => {
          this.emailVerified.emit();
          localStorage.removeItem(ELocalStorageKey.VerifySent);
        },
        error: err => {
          if (err.status === 200) {
            this.emailVerified.emit();
            localStorage.removeItem(ELocalStorageKey.VerifySent);
          } else {
            console.error(err);
          }
        }
      });
  }
}
