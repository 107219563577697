<span
  [ngClass]="{
    filled: iconConfig.fill === ESvgTypes.Filled,
    tonal: iconConfig.fill === ESvgTypes.Tonal,
    outlined: iconConfig.fill === ESvgTypes.Outlined,
    'outlined-dark': iconConfig.fill === ESvgTypes.OutlinedDark,
    none: iconConfig.fill === ESvgTypes.None,
    'color-filled-lighter': iconConfig.fill === ESvgTypes.ColorFilledLighter,
    'color-filled-darker': iconConfig.fill === ESvgTypes.ColorFilledDarker,
    disabled: disabled
  }"
  class="svg-icon"
  [innerHTML]="svgIcon"
  [attr.tabindex]="disabled ? -1 : 0"
  [attr.aria-disabled]="disabled ? true : null"
></span>
