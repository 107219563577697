import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from '../../shared/components/button/button.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';

import { EButtonSizes, EButtonTypes } from 'app/shared/enums/button.enums';

@Component({
  selector: 'stxt-subscription-paywall',
  standalone: true,
  imports: [ CommonModule, ButtonComponent ],
  templateUrl: './subscription-paywall.component.html',
  styleUrl: './subscription-paywall.component.scss'
})
export class SubscriptionPaywallComponent {
  @Input() creatorImg: string;
  @Input() subscriptionPrice: number;
  @Output() subscribeEvent: EventEmitter<void> = new EventEmitter<void>();
  subscribeBtn: IButtonConfig = {
    text: 'Subscribe', fill: EButtonTypes.SecondaryFilled, buttonSize: EButtonSizes.Default
  }
}
