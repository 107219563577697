"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var StatsigUser_1 = require("../StatsigUser");
var EvaluationReason_1 = require("./EvaluationReason");
var Hashing_1 = require("./Hashing");
var BootstrapValidator = /** @class */function () {
  function BootstrapValidator() {}
  BootstrapValidator.getEvaluationReasonForBootstrap = function (user, values, stableID) {
    var isValid = true;
    var stableIDMistmatch = false;
    try {
      var evaluatedKeys = values['evaluated_keys'];
      if (evaluatedKeys && typeof evaluatedKeys === 'object') {
        var evaluatedKeysRecord = this.copyObject(evaluatedKeys);
        var userToCompare = user == null ? null : this.copyObject(user);
        isValid = isValid && BootstrapValidator.validate(evaluatedKeysRecord, userToCompare) && BootstrapValidator.validate(userToCompare, evaluatedKeysRecord);
        var customIDs = evaluatedKeys.customIDs;
        if (stableID != (customIDs === null || customIDs === void 0 ? void 0 : customIDs.stableID)) {
          stableIDMistmatch = true;
        }
      }
      var userHash = values['user_hash'];
      if (userHash && typeof userHash === 'string' && user != null) {
        var userHashGood = userHash === (0, StatsigUser_1.getUserHashWithoutStableID)(user) || userHash === (0, Hashing_1.djb2HashForObject)(__assign(__assign({}, user), {
          stableID: stableID
        }));
        isValid = isValid && userHashGood;
      }
      var bootstrapUser = values['user'];
      if (bootstrapUser && typeof bootstrapUser === 'object' && user != null) {
        isValid = isValid && BootstrapValidator.validate(this.copyObject(bootstrapUser), this.copyObject(user)) && BootstrapValidator.validate(this.copyObject(user), this.copyObject(bootstrapUser));
      }
    } catch (error) {
      // This is best-effort. If we fail, return true.
    }
    return !isValid ? EvaluationReason_1.EvaluationReason.InvalidBootstrap : stableIDMistmatch ? EvaluationReason_1.EvaluationReason.BootstrapStableIDMismatch : EvaluationReason_1.EvaluationReason.Bootstrap;
  };
  BootstrapValidator.validate = function (one, two) {
    if (one == null) {
      return two == null;
    } else if (two == null) {
      return false;
    }
    for (var _i = 0, _a = Object.entries(one); _i < _a.length; _i++) {
      var _b = _a[_i],
        key = _b[0],
        value = _b[1];
      if (key === 'stableID') {
        continue;
      }
      if (typeof value !== typeof two[key]) {
        return false;
      }
      if (typeof value === 'string') {
        if (value !== two[key]) {
          return false;
        }
      } else if (typeof value === 'object') {
        return this.validate(value, two[key]);
      } else {
        // unexpected
        return false;
      }
    }
    return true;
  };
  BootstrapValidator.copyObject = function (obj) {
    if (obj == null) {
      return null;
    }
    var copy = {};
    if (obj === null || obj === void 0 ? void 0 : obj.userID) {
      copy['userID'] = obj === null || obj === void 0 ? void 0 : obj.userID;
    }
    if (obj === null || obj === void 0 ? void 0 : obj.customIDs) {
      var customIDs = __assign({}, obj.customIDs);
      delete customIDs['stableID'];
      if (Object.keys(customIDs).length !== 0) {
        copy['customIDs'] = customIDs;
      }
    }
    return copy;
  };
  return BootstrapValidator;
}();
exports.default = BootstrapValidator;