import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';

import { NavigationIntentService } from 'app/shared/services/navigation-intent.service';
import { AuthService } from '../../auth/auth.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { NumberCountPipe } from 'app/pipes/number-count.pipe';

import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';
import { IDiscoverItem } from '../../interfaces/discover.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-discover-item',
  standalone: true,
  imports: [CommonModule, RecaptchaV3Module, SvgIconComponent, NumberCountPipe],
  templateUrl: './discover-item.component.html',
  styleUrl: './discover-item.component.scss',
  providers: [ReCaptchaV3Service]
})
export class DiscoverItemComponent implements OnInit {
  @Input() discoverItem: IDiscoverItem;
  @Input() userId: string;
  public creatorInfo: { creator_name: string; thumbnail_image: string };
  public chatSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'chat-outlined' };
  public chatFilledSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'chat-filled' };
  public clockSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'clock' };
  public clockFilledSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'clock-filled' };
  public creatorProfileSvg: ISvgConfig = { fill: ESvgTypes.OutlinedDark, name: 'profile' };
  public followersSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'followers' };
  public verifySvg: ISvgConfig = { fill: ESvgTypes.None, name: 'verified' };
  public heatSvg: ISvgConfig = { fill: ESvgTypes.None, name: 'mode_heat' };
  public isChatLoading: boolean = false;
  public user = JSON.parse(localStorage.getItem('user'));
  public isMobile: boolean = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public modal: NgbModal,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private navigationIntentService: NavigationIntentService
  ) {}

  ngOnInit(): void {
    this.creatorInfo = {
      creator_name: this.discoverItem.name,
      thumbnail_image: this.discoverItem.image
    };
    this.checkScreenSize();
  }

  openCreatorProfile(): void {
    if (!this.discoverItem.ready) return;
    localStorage.setItem('creatorInfo', JSON.stringify(this.creatorInfo));
    this.router.navigateByUrl(`creator/${this.discoverItem.id}`);
  }

  async openChat(event: Event): Promise<void> {
    if (!this.discoverItem.ready) return;
    event.stopPropagation();
    if (this.isChatLoading) return;
    this.isChatLoading = true;

    try {
      await this.processChatNavigation();
    } finally {
      this.isChatLoading = false;
    }
  }

  private async processChatNavigation(): Promise<void> {
    if (!this.user) {
      try {
        await firstValueFrom(this.reCaptchaV3Service.execute('chat_now_action'));
      } catch (error) {
        console.error('reCAPTCHA execution error:', error);
        return;
      }
    }

    await this.authService.checkUserCredentials();
    localStorage.setItem('backUrl', '/');
    localStorage.setItem('creatorInfo', JSON.stringify(this.creatorInfo));
    if (this.authService.userExist$.value && this.authService.userLoaded$.value) {
      this.userId = localStorage.getItem('userId');
      await this.router.navigateByUrl(`chat/${this.discoverItem.id}`);
    } else {
      this.userId = localStorage.getItem('userId');
      this.navigationIntentService.setIntent(`/chat/${this.discoverItem.id}`);
      await this.router.navigateByUrl(`chat/${this.discoverItem.id}`);
    }
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 640;
  }

  truncate(text: string, length: number): string {
    return this.isMobile && text.length > length ? text.substring(0, length) + '...' : text;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }
}
