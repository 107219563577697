"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserCacheKey = exports.getSortedObject = exports.djb2HashForObject = exports.sha256Hash = exports.djb2Hash = exports.memoizedUserCacheKeyHash = exports.fasthash = void 0;
var js_sha256_1 = require("js-sha256");
var Base64_1 = require("./Base64");
var hashLookupTable = {};
function fasthash(value) {
  var hash = 0;
  for (var i = 0; i < value.length; i++) {
    var character = value.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
exports.fasthash = fasthash;
// Keeping this around to prevent busting existing caches
// This is just the same as djb2Hash but it can have negative values
function memoizedUserCacheKeyHash(value) {
  var seen = hashLookupTable[value];
  if (seen) {
    return seen;
  }
  var hash = String(fasthash(value));
  hashLookupTable[value] = hash;
  return hash;
}
exports.memoizedUserCacheKeyHash = memoizedUserCacheKeyHash;
function djb2Hash(value) {
  return String(fasthash(value) >>> 0);
}
exports.djb2Hash = djb2Hash;
function sha256Hash(value) {
  var seen = hashLookupTable[value];
  if (seen) {
    return seen;
  }
  var buffer = js_sha256_1.sha256.create().update(value).arrayBuffer();
  var hash = Base64_1.Base64.encodeArrayBuffer(buffer);
  hashLookupTable[value] = hash;
  return hash;
}
exports.sha256Hash = sha256Hash;
function djb2HashForObject(object) {
  return djb2Hash(JSON.stringify(getSortedObject(object)));
}
exports.djb2HashForObject = djb2HashForObject;
function getSortedObject(object) {
  if (object == null) {
    return null;
  }
  var keys = Object.keys(object).sort();
  var sortedObject = {};
  keys.forEach(function (key) {
    var value = object[key];
    if (value instanceof Object) {
      value = getSortedObject(value);
    }
    sortedObject[key] = value;
  });
  return sortedObject;
}
exports.getSortedObject = getSortedObject;
function getUserCacheKey(stableID, user, sdkKey) {
  var _a;
  var parts = ["userID:".concat(String((_a = user === null || user === void 0 ? void 0 : user.userID) !== null && _a !== void 0 ? _a : ''))];
  var customIDs = user === null || user === void 0 ? void 0 : user.customIDs;
  if (customIDs != null) {
    for (var _i = 0, _b = Object.entries(customIDs); _i < _b.length; _i++) {
      var _c = _b[_i],
        type = _c[0],
        value = _c[1];
      parts.push("".concat(type, ":").concat(value));
    }
  }
  var v2 = memoizedUserCacheKeyHash(parts.join(';'));
  var v3 = memoizedUserCacheKeyHash(parts.join(';') + ";sdkKey:".concat(sdkKey));
  parts.splice(1, 0, "stableID:".concat(stableID));
  var v1 = memoizedUserCacheKeyHash(parts.join(';'));
  return {
    v1: v1,
    v2: v2,
    v3: v3
  };
}
exports.getUserCacheKey = getUserCacheKey;