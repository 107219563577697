"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DynamicConfig = /** @class */function () {
  function DynamicConfig(configName, configValue, ruleID, evaluationDetails, secondaryExposures, allocatedExperimentName, onDefaultValueFallback, groupName, idType, isExperimentActive) {
    if (secondaryExposures === void 0) {
      secondaryExposures = [];
    }
    if (allocatedExperimentName === void 0) {
      allocatedExperimentName = '';
    }
    if (onDefaultValueFallback === void 0) {
      onDefaultValueFallback = null;
    }
    if (groupName === void 0) {
      groupName = null;
    }
    if (idType === void 0) {
      idType = null;
    }
    if (isExperimentActive === void 0) {
      isExperimentActive = null;
    }
    this.onDefaultValueFallback = null;
    this.name = configName;
    this.value = JSON.parse(JSON.stringify(configValue !== null && configValue !== void 0 ? configValue : {}));
    this.ruleID = ruleID !== null && ruleID !== void 0 ? ruleID : '';
    this.secondaryExposures = secondaryExposures;
    this.allocatedExperimentName = allocatedExperimentName;
    this.evaluationDetails = evaluationDetails;
    this.onDefaultValueFallback = onDefaultValueFallback;
    this.groupName = groupName;
    this.idType = idType;
    this.isExperimentActive = isExperimentActive;
  }
  DynamicConfig.prototype.get = function (key, defaultValue, typeGuard) {
    var _a, _b;
    var val = this.getValue(key, defaultValue);
    if (val == null) {
      return defaultValue;
    }
    var expectedType = Array.isArray(defaultValue) ? 'array' : typeof defaultValue;
    var actualType = Array.isArray(val) ? 'array' : typeof val;
    if (typeGuard) {
      if (typeGuard(val)) {
        return val;
      }
      (_a = this.onDefaultValueFallback) === null || _a === void 0 ? void 0 : _a.call(this, this, key, expectedType, actualType);
      return defaultValue;
    }
    if (defaultValue == null || expectedType === actualType) {
      return val;
    }
    (_b = this.onDefaultValueFallback) === null || _b === void 0 ? void 0 : _b.call(this, this, key, expectedType, actualType);
    return defaultValue;
  };
  DynamicConfig.prototype.getValue = function (key, defaultValue) {
    if (key == null) {
      return this.value;
    }
    if (defaultValue == null) {
      defaultValue = null;
    }
    if (this.value[key] == null) {
      return defaultValue;
    }
    return this.value[key];
  };
  DynamicConfig.prototype.getRuleID = function () {
    return this.ruleID;
  };
  DynamicConfig.prototype.getGroupName = function () {
    return this.groupName;
  };
  DynamicConfig.prototype.getIDType = function () {
    return this.idType;
  };
  DynamicConfig.prototype.getName = function () {
    return this.name;
  };
  DynamicConfig.prototype.getEvaluationDetails = function () {
    return this.evaluationDetails;
  };
  DynamicConfig.prototype._getSecondaryExposures = function () {
    return this.secondaryExposures;
  };
  DynamicConfig.prototype._getAllocatedExperimentName = function () {
    return this.allocatedExperimentName;
  };
  DynamicConfig.prototype.getIsExperimentActive = function () {
    return this.isExperimentActive;
  };
  return DynamicConfig;
}();
exports.default = DynamicConfig;