import { Component, Input, OnInit } from '@angular/core';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EChatFeatureAction } from 'app/enums/conversation';

@Component({
  selector: 'stxt-chat-feature-button',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './chat-feature-button.component.html',
  styleUrl: './chat-feature-button.component.scss'
})
export class ChatFeatureButtonComponent implements OnInit {
  @Input() featureNavOption: string;
  buttonIcon: ISvgConfig = {
    name: 'interactive',
    fill: ESvgTypes.None
  };

  ngOnInit() {
    this.buttonIcon.name = this.initSvgIcon();
  }

  initSvgIcon(): string {
    switch (this.featureNavOption) {
      case EChatFeatureAction.RELOAD:
        return 'regenerate';
      case EChatFeatureAction.SURPRISE_ME:
        return 'favorite';
      case EChatFeatureAction.CLOSE:
        return 'close';
      default:
        return 'interactive';
    }
  }
}
