import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EButtonTypes } from 'app/shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';

@Component({
  selector: 'stxt-payment-failed',
  standalone: true,
  imports: [ButtonComponent, SvgIconComponent],
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss'
})
export class PaymentFailedComponent {
  @Input() paymentType: string;
  public continueBtn: IButtonConfig = {
    text: 'Continue',
    fill: EButtonTypes.Colored
  };
  public closeSvg: ISvgConfig = { fill: ESvgTypes.Outlined, name: 'close' };

  constructor(public activeModal: NgbActiveModal) {}

  close(): void {
    this.activeModal.close();
  }
}
