<div class="add-payment-method">
  <div>
    <div class="add-payment-method_head">
      <stxt-svg-icon
        [iconConfig]="arrowSvg"
        (click)="goBack()"
        (keydown.enter)="goBack()"
        tabindex="0"
        class="checkout_icon"
      ></stxt-svg-icon>
      <p class="checkout_heading">Add payment method</p>
      <div></div>
    </div>
    <stxt-payment-form
      [creatorId]="creatorId"
      [wsConnectionId]="wsConnectionId"
    ></stxt-payment-form>
    <stxt-code-verify *ngIf="firstCheckOut"></stxt-code-verify>
  </div>
  <div class="add-payment-method_footer">
    <stxt-button [buttonConfig]="addMethodBtn">{{ addMethodBtn.text }}</stxt-button>
  </div>
</div>
