export enum EInputCustomType {
  Email = 'email',
  Text = 'text',
  Password = 'password',
  Number = 'number'
}

export enum EInputSupportMessage {
  Email = 'Please enter a valid email address',
  EmailNotFound = 'This email isn’t linked to an STXT account. Use this email to',
  EmailExists = 'This email is linked to an STXT account. Use this email to',
  Password = 'Must be minimum 8 characters with at least one number, uppercase and lowercase letter and symbol.',
  PasswordMatch = 'Password doesn’t match',
  PasswordRequired = 'Password required',
  PasswordHint = 'Must contain at least 1 number, special character, uppercase letter, and lowercase letter',
  ZipRequired = 'ZIP or postal code required',
  FirstNameRequired = 'First name required',
  LastNameRequired = 'Last name required',
  VerifyCodeRequired = 'Please enter a 6-digit code'
}

export enum EInputUsage {
  Page = 'page',
  Modal = 'modal'
}
