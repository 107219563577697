<div class="payment-failed">
  <div class="payment-failed_modal-head">
    <stxt-svg-icon
      (keydown.enter)="close()"
      (click)="close()"
      [iconConfig]="closeSvg"
      class="checkout_icon"
    ></stxt-svg-icon>
  </div>
  <h4 class="payment-failed_heading">Payment Incomplete</h4>
  <p class="payment-failed_subheading">
    We're sorry, but your {{ paymentType }} failed. Please try again or contact support if the issue
    persists.
  </p>
  <!-- TODO: Add image if design is provided -->
  <!-- <img class="payment-failed_image" src="../../../../assets/d2c-images/payment-succeed.png" alt="payment-succeed"/> -->
  <stxt-button
    (keydown.enter)="close()"
    (click)="close()"
    class="payment-failed_btn"
    [buttonConfig]="continueBtn"
  >
    {{ continueBtn.text }}
  </stxt-button>
</div>
