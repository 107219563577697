<div class="chats">
  <div class="chats_wrapper">
    <h3 *ngIf="conversationsList && conversationsList.length > 0" class="chats_heading">Chat</h3>

    <ng-container *ngIf="conversationsLoaded; else loading">
      <ng-container *ngIf="!conversationsList || conversationsList.length === 0">
        <div class="chats_empty">
          <p class="chats_empty__header">No conversations yet</p>
          <p class="chats_empty__description">Start a conversation with your favorite creators</p>
          <img class="chats_empty__image" src="assets/d2c-images/no_chats.png" alt="empty-chat" />
          <stxt-button
            [buttonConfig]="discoverButtonConfig"
            (click)="discover()"
            (keydown.enter)="discover()"
            class="chats_empty__button"
          >
            {{ discoverButtonConfig.text }}
          </stxt-button>
        </div>
      </ng-container>
      <stxt-chat-item
        *ngFor="let conversation of conversationsList"
        [conversation]="conversation"
        class="chats_item"
      ></stxt-chat-item>
    </ng-container>

    <ng-template #loading></ng-template>
  </div>
</div>
