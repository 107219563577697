<div class="purchase_item flex space-between">
  <p>
      <span
        [ngClass]="{
			'paid': purchase.state === 'paid',
			'canceled': purchase.state === 'canceled'
		  }"
        class="purchase_item__status">
      </span>
    {{ purchase.state | titlecase }}
    <span *ngIf="purchase.state === 'canceled'">payment</span>
    {{ purchase.total | currency }}
    total on {{ purchase.closed_at | date: "MM/dd/yyyy" }}
  </p>
  <stxt-svg-icon
    [iconConfig]="rightNavSvg"
    (click)="openTransactionInfo(purchase)"
    class="purchase_item__svg">
  </stxt-svg-icon>
</div>
