<div class="payment-auth">
  <form *ngIf="isVerifyingState && !verifiedState" [formGroup]="verifyForm">
    <div class="payment-auth_verify__head">
      <stxt-svg-icon [iconConfig]="checkVerifyIcon"></stxt-svg-icon>
      <div>
        <p class="payment-auth_verify__heading">Verify email address</p>
        <p class="payment-auth_verify__subheading">
          Enter the 6-digit code sent to {{ email }}
          <span class="resend-code">
            Didn’t receive it?
            <stxt-resend-code
              [usageCase]="EResendCodeUsage.Chat"
              (resendCodeSent)="isToastMessage = true"
            ></stxt-resend-code>
          </span>
        </p>
      </div>
    </div>
    <div class="flex payment-auth_verify">
      <stxt-input
        [inputConfig]="verifyInput"
        class="payment_input payment-auth_verify__input"
        formControlName="code"
      ></stxt-input>
      <stxt-button
        (click)="submitVerify()"
        (keydown.enter)="submitVerify()"
        [buttonConfig]="verifyButton"
        class="payment-auth_button__verify"
      >
        {{ verifyButton.text }}
      </stxt-button>
    </div>
  </form>
  <div *ngIf="verifiedState" class="payment-auth_verify__head">
    <stxt-svg-icon [iconConfig]="checkVerifyIcon"></stxt-svg-icon>
    <div>
      <p class="payment-auth_verify__heading">Your STXT account is now set up</p>
      <p class="payment-auth_verify__subheading">You can log in using your email and password.</p>
    </div>
  </div>
  <ng-container *ngIf="isToastMessage">
    <stxt-success-toast
      [toastHeading]="'Security code was resent'"
      [toastSubheading]="'Please check your email for another 6-digit code.'"
      (closeToast)="isToastMessage = false"
      class="payment-auth_resend-toast w-full"
    ></stxt-success-toast>
  </ng-container>
</div>
