<section class="manage-credential w-full">
  <h4 class="manage-credential_heading">{{ userCredential }}</h4>
  <p class="manage-credential_subheading">Set your email address and password to access your account</p>
  <form class="manage-credential_form flex flex-column space-between" [formGroup]="credentialForm">
    <div>
      <ng-container *ngIf="userCredential === EUserCredential.Email">
        <stxt-input [inputConfig]="emailInput"
                    formControlName="email"
        ></stxt-input>
      </ng-container>
      <ng-container *ngIf="userCredential === EUserCredential.Password">
        <stxt-input [inputConfig]="oldPasswordInput"
                    formControlName="oldPassword">
        </stxt-input>
        <stxt-input [inputConfig]="passwordInput"
                    formControlName="password">
        </stxt-input>
        <stxt-input [inputConfig]="password2Input"
                    [hintText]="password2Input.hintText"
                    formControlName="password2">
        </stxt-input>
      </ng-container>
    </div>
    <stxt-button [disabled]="passwordMismatch || !credentialForm.get('password2')?.value || credentialForm.invalid"
                 [buttonConfig]="confirmButton"
                 (click)="updatePassword()">
          {{ confirmButton.text }}
    </stxt-button>
  </form>
</section>
