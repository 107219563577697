"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Layer = /** @class */function () {
  function Layer(name, layerValue, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters, groupName) {
    if (logParameterFunction === void 0) {
      logParameterFunction = null;
    }
    if (secondaryExposures === void 0) {
      secondaryExposures = [];
    }
    if (undelegatedSecondaryExposures === void 0) {
      undelegatedSecondaryExposures = [];
    }
    if (allocatedExperimentName === void 0) {
      allocatedExperimentName = '';
    }
    if (explicitParameters === void 0) {
      explicitParameters = [];
    }
    if (groupName === void 0) {
      groupName = null;
    }
    this.logParameterFunction = logParameterFunction;
    this.name = name;
    this.value = JSON.parse(JSON.stringify(layerValue !== null && layerValue !== void 0 ? layerValue : {}));
    this.ruleID = ruleID !== null && ruleID !== void 0 ? ruleID : '';
    this.evaluationDetails = evaluationDetails;
    this.secondaryExposures = secondaryExposures;
    this.undelegatedSecondaryExposures = undelegatedSecondaryExposures;
    this.allocatedExperimentName = allocatedExperimentName;
    this.explicitParameters = explicitParameters;
    this.groupName = groupName;
  }
  Layer._create = function (name, value, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters, groupName) {
    if (logParameterFunction === void 0) {
      logParameterFunction = null;
    }
    if (secondaryExposures === void 0) {
      secondaryExposures = [];
    }
    if (undelegatedSecondaryExposures === void 0) {
      undelegatedSecondaryExposures = [];
    }
    if (allocatedExperimentName === void 0) {
      allocatedExperimentName = '';
    }
    if (explicitParameters === void 0) {
      explicitParameters = [];
    }
    if (groupName === void 0) {
      groupName = null;
    }
    return new Layer(name, value, ruleID, evaluationDetails, logParameterFunction, secondaryExposures, undelegatedSecondaryExposures, allocatedExperimentName, explicitParameters, groupName);
  };
  Layer.prototype.get = function (key, defaultValue, typeGuard) {
    var _this = this;
    var val = this.value[key];
    if (val == null) {
      return defaultValue;
    }
    var logAndReturn = function () {
      _this.logLayerParameterExposure(key);
      return val;
    };
    if (typeGuard) {
      return typeGuard(val) ? logAndReturn() : defaultValue;
    }
    if (defaultValue == null) {
      return logAndReturn();
    }
    if (typeof val === typeof defaultValue && Array.isArray(defaultValue) === Array.isArray(val)) {
      return logAndReturn();
    }
    return defaultValue;
  };
  Layer.prototype.getValue = function (key, defaultValue) {
    if (defaultValue == undefined) {
      defaultValue = null;
    }
    var val = this.value[key];
    if (val != null) {
      this.logLayerParameterExposure(key);
    }
    return val !== null && val !== void 0 ? val : defaultValue;
  };
  Layer.prototype.getRuleID = function () {
    return this.ruleID;
  };
  Layer.prototype.getGroupName = function () {
    return this.groupName;
  };
  Layer.prototype.getName = function () {
    return this.name;
  };
  Layer.prototype.getEvaluationDetails = function () {
    return this.evaluationDetails;
  };
  Layer.prototype._getSecondaryExposures = function () {
    return this.secondaryExposures;
  };
  Layer.prototype._getUndelegatedSecondaryExposures = function () {
    return this.undelegatedSecondaryExposures;
  };
  Layer.prototype._getAllocatedExperimentName = function () {
    return this.allocatedExperimentName;
  };
  Layer.prototype._getExplicitParameters = function () {
    return this.explicitParameters;
  };
  Layer.prototype._getEvaluationDetails = function () {
    return this.evaluationDetails;
  };
  Layer.prototype.logLayerParameterExposure = function (parameterName) {
    var _a;
    (_a = this.logParameterFunction) === null || _a === void 0 ? void 0 : _a.call(this, this, parameterName);
  };
  return Layer;
}();
exports.default = Layer;