<section *ngIf="transactionLoaded" class="transaction-info">
  <h6 class="transaction-info_purchase__heading">Purchase summary</h6>
  <div class="transaction-info_purchase__wrapper">
    <div class="checkout_info">
      <div class="checkout_info__row flex">
        <p>{{ transactionItem.line_items[0].description }}</p>
      </div>
      <div class="checkout_info__row flex">
        <p>Subtotal</p>
        <p>{{ transactionItem.payment_detail.subtotal | currency }}</p>
      </div>
      <div class="checkout_info__row flex">
        <p>Processing fees</p>
        <p>{{ transactionItem.payment_detail.tax | currency }}</p>
      </div>
      <div class="checkout_total flex">
        <p>Total</p>
        <p>{{ transactionItem.payment_detail.total | currency }}</p>
      </div>
    </div>
  </div>

  <h6 class="transaction-info_details__heading">Transaction details</h6>
  <div class="transaction-info_details__wrapper flex flex-column">
    <div class="transaction-info_details__item">
      <div class="transaction-info_details__parameter flex">
        <stxt-svg-icon [iconConfig]="checkSvg" class="transaction-info__svg"></stxt-svg-icon>
        <p>Payment status</p>
      </div>
      <p class="transaction-info_details__value">
              <span
                [ngClass]="{
			'paid': transactionItem.state === 'paid',
			'canceled': transactionItem.state === 'canceled'
		  }"
                class="transaction-info_status">
      </span>
        {{ transactionItem.state | titlecase }}
      </p>
    </div>
    <div class="transaction-info_details__item">
      <div class="transaction-info_details__parameter flex">
        <stxt-svg-icon [iconConfig]="dateSvg" class="transaction-info__svg"></stxt-svg-icon>
        <p> Purchase date</p>
      </div>
      <p class="transaction-info_details__value">
        {{ transactionItem.closed_at | date: 'short' }}
      </p>
    </div>
    <div class="transaction-info_details__item">
      <div class="transaction-info_details__parameter flex">
        <stxt-svg-icon [iconConfig]="cardSvg" class="transaction-info__svg"></stxt-svg-icon>
        <p>Payment method</p>
      </div>
      <p class="transaction-info_details__value">
        {{ transactionItem.payment_method.card_type }} *{{ transactionItem.payment_method.last_four }}
      </p>
    </div>
    <div class="transaction-info_details__item">
      <div class="transaction-info_details__parameter flex">
        <stxt-svg-icon [iconConfig]="hashtagSvg" class="transaction-info__svg"></stxt-svg-icon>
        <p>Transaction ID</p>
      </div>
      <p class="transaction-info_details__value">
        {{ transactionItem.id }}
      </p>
    </div>
  </div>
  <stxt-checkout-additional-info></stxt-checkout-additional-info>
</section>
