import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule }                                   from '@angular/common';
import { DomSanitizer, SafeUrl }                          from '@angular/platform-browser';

import { MessageMediaComponent } from './message-media/message-media/message-media.component';
import { MessageAudioComponent } from './message-media/message-audio/message-audio.component';

import { IMessage }              from 'app/interfaces/conversations.interfaces';

@Component({
  selector: 'stxt-message',
  standalone: true,
  imports: [ CommonModule, MessageMediaComponent, MessageAudioComponent ],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit {
  @Input() messageItem: IMessage;
  @Input() userId: string;
  @Input() creatorName: string;
  @Input() creatorId: string;
  @Output() triggerPayment: EventEmitter<void> = new EventEmitter<void>();
  @Output() media: EventEmitter<void> = new EventEmitter<void>();
  @Output() isPreviewMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  safeUrl: SafeUrl;
  voiceUrl: SafeUrl;
  mediaLoaded: boolean = false;
  isMessageExpanded: boolean;

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.messageItem.category === 'voice') {
      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.messageItem.payload?.url);
    } else if (this.messageItem?.voice_url?.length) {
      this.voiceUrl = this.sanitizer.bypassSecurityTrustUrl(this.messageItem.voice_url);
    }
  }

  expandVoiceText(): void {
    this.isMessageExpanded = !this.isMessageExpanded;
  }

  truncateVoiceText(text: string, isExpanded: boolean): string {
    if (isExpanded) {
      return text;
    }
    return text.length > 30 ? text.substring(0, 45) + '...' : text;
  }
}
