import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from '../../../../shared/components/svg-icon/svg-icon.component';

import { ISvgConfig } from '../../../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../../../shared/enums/svg.enums';
import { ECreatorVideoTypes } from '../../../../enums/creator.enums';

@Component({
  selector: 'stxt-media-video',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './media-video.component.html',
  styleUrl: './media-video.component.scss'
})
export class MediaVideoComponent {
  @Input() media: string;
  @Input() mediaId: string;
  @Input() videoType: string;
  @Input() videoPaused: boolean;
  isPlaying: boolean = false;

  playIcon: ISvgConfig = {
    name: 'play_black',
    fill: ESvgTypes.None
  };
  pauseIcon: ISvgConfig = {
    name: 'pause',
    fill: ESvgTypes.None
  };
  protected readonly ECreatorVideoTypes = ECreatorVideoTypes;

  togglePlayPause(event: Event): void {
    event.stopPropagation();
    const video = document.getElementById(this.mediaId) as HTMLVideoElement;
    if (video.paused) {
      video.play();
      this.isPlaying = true;
    } else {
      video.pause();
      this.isPlaying = false;
    }
  }

  pauseVideo(): void {
    const video = document.getElementById(this.mediaId) as HTMLVideoElement;
    video.pause();
    this.isPlaying = false;
  }
}
