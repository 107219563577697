export const environment = {
  apiBaseUrl: 'https://5c4q26c1x1.execute-api.us-east-1.amazonaws.com/v1',
  wssBaseUrl: 'wss://sisywgle31.execute-api.us-east-1.amazonaws.com/development/',
  userPoolClientId: '4h7po2jo69ii09j0in4297gju8',
  userPoolId: 'us-east-1_kP6RUtCDc',
  domain: 'auth-dev-users-k8bs.auth.us-east-1.amazoncognito.com',
  redirectSignIn: '["http://localhost:4200/","https://develop.dkmdrscfl7fop.amplifyapp.com/","https://develop.stxt.ai/","https://www.develop.stxt.ai/"]',
  redirectSignOut: '["http://localhost:4200/","https://develop.stxt.ai/","https://www.develop.stxt.ai/"]',
  analyticsKey: 'XDDLL8VKaRhcZnqbohuuoMQG1QA1kDtO',
  recaptchaSiteKey: '6LcmnTYqAAAAANsRhUGDBlj5-zbvyO2Drod9Og38',
  statsigKey: 'client-fUWKCtTZ80BaCPU5YmZfawFDNbzPcSPjdLYyaCutJ2w',
  sentryDsn: 'https://f6d2e41e63562866d33e0f0542859d48@o4505800103755776.ingest.us.sentry.io/4507983054438400',
  sentryAuthToken: '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',
  serviceEnvironment: 'development',
  production: true,
};