import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ConversationService } from '../../shared/services/conversation.service';

import { SvgIconComponent } from '../../shared/components/svg-icon/svg-icon.component';

import { IConversation } from '../../interfaces/conversations.interfaces';
import { ISvgConfig } from '../../shared/interfaces/svg.interfaces';

import { ESvgTypes } from '../../shared/enums/svg.enums';

@Component({
  selector: 'stxt-chat-item',
  standalone: true,
  imports: [SvgIconComponent, CommonModule],
  templateUrl: './chat-item.component.html',
  styleUrl: './chat-item.component.scss'
})
export class ChatItemComponent implements OnInit {
  @Input() conversation: IConversation;
  public navIcon: ISvgConfig = {
    name: 'arrow_right', fill: ESvgTypes.None
  };
  formattedDate: string;

  constructor(
    public router: Router,
    public conversationService: ConversationService
  ) {
  }

  ngOnInit(): void {
    this.formattedDate = this.conversationService
      .getFormattedDate(new Date(Number(this.conversation.last_message_timestamp)));
  }

  openChat(): void {
    localStorage.setItem('backUrl', '/chats');
    localStorage.setItem('creatorInfo', JSON.stringify(this.conversation));
    this.router.navigateByUrl(`chat/${this.conversation.conversation_id.split('-')[0]}`);
  }
}
