<div
  (click)="openChat($event)"
  (keydown.enter)="openChat($event)"
  class="discover-card relative"
  tabindex="-1"
>
  <div class="discover-card_media">
    <img class="primary-image" [src]="discoverItem?.image" alt="{{ discoverItem.name }}" />
    <img
      class="secondary-image"
      [src]="
        discoverItem?.secondary_image !== '' ? discoverItem?.secondary_image : discoverItem?.image
      "
      alt="{{ discoverItem.secondary_image }}"
    />
  </div>
  <div class="discover-card_nav absolute" [ngClass]="{ 'coming-soon': !discoverItem?.ready }">
    <ng-container
      *ngTemplateOutlet="discoverItem?.ready ? activeCreators : comingSoonCreators"
    ></ng-container>
  </div>
  <div class="discover-card_body absolute">
    <div class="discover-card_info">
      <div class="flex discover-card_head">
        <div class="discover-card_name relative">
          {{ truncate(discoverItem.name, 15) }}
          <span class="discover-card_ai absolute">
            <stxt-svg-icon class="verify-svg" [iconConfig]="verifySvg"></stxt-svg-icon>
          </span>
        </div>
      </div>
      <div class="discover-card_additional-info flex items-center">
        <div class="discover-card_additional-info__sfw flex">
          <stxt-svg-icon class="item_svg fire-svg" [iconConfig]="heatSvg"></stxt-svg-icon>
          <p>NSFW</p>
        </div>
        <span class="discover-card_divider">•</span>
        <div class="discover-card_additional-info__content flex">
          <div class="discover-card_additional-info__item flex">
            <stxt-svg-icon
              class="item_svg followers-svg"
              [iconConfig]="followersSvg"
            ></stxt-svg-icon>
            <p>{{ discoverItem.followers | numberCount }}</p>
          </div>
        </div>
      </div>
      <p class="discover-card_description">{{ discoverItem.description }}</p>
    </div>
  </div>
</div>

<ng-template #activeCreators>
  <stxt-svg-icon
    class="discover-card_svg chat_outlined"
    [iconConfig]="chatSvg"
    [disabled]="isChatLoading"
    (click)="openChat($event)"
    (keydown.enter)="openChat($event)"
    tabindex="-1"
  ></stxt-svg-icon>
  <stxt-svg-icon
    class="discover-card_svg chat_filled"
    [iconConfig]="chatFilledSvg"
    [disabled]="isChatLoading"
    (click)="openChat($event)"
    (keydown.enter)="openChat($event)"
    tabindex="-1"
  ></stxt-svg-icon>
</ng-template>

<ng-template #comingSoonCreators>
  <div class="discover-card_coming-soon">
    <div class="coming-soon_svg__wrapper relative">
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_outlined"
        [iconConfig]="clockSvg"
      ></stxt-svg-icon>
      <stxt-svg-icon
        class="coming-soon_svg clock-svg_hovered"
        [iconConfig]="clockFilledSvg"
      ></stxt-svg-icon>
    </div>
    <p>COMING SOON</p>
  </div>
</ng-template>
